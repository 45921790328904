import { ReactElement, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAuthUser } from '@tactileentertainment/core-shared.auth-react';
import { AppThunkDispatch } from '../../store';
import ClearFiltersButton from '../commons/filters/ClearFiltersButton';
import ColumnFilter from '../commons/filters/ColumnFilter';
import { FiltersContainer, CollapseFilters } from '../commons/filters/commons';
import { parseFilterOptions, sortNumberOptions, sortVersionOptions } from '../commons/filters/utils';
import getBuildAgentName from '../commons/getBuildAgentName';
import { IBuildAgent } from '../commons/interfaces';
import {
    getBuilds,
    selectFilterAuthor,
    selectFilterBranch,
    selectFilterType,
    selectFilterProjectName,
    selectFilterPlatform,
    selectFilterRevision,
    selectFilterAssetRevision,
    selectFilterStatus,
    selectFilterVersionName,
    selectFilterBuildAgent,
    setFilterAuthor,
    setFilterBranch,
    setFilterType,
    setFilterProjectName,
    setFilterPlatform,
    setFilterRevision,
    setFilterAssetRevision,
    setFilterStatus,
    setFilterVersionName,
    setFilterBuildAgent,
    selectBuildsColumns,
    getBuildsExtras,
    selectFilterKind,
    setFilterKind,
    selectAmountOfActiveBuildsFilters,
    clearAllFilters,
    getBuildActions,
} from './buildsSlice';
import { BuildsColumnId } from './enums';
import { IBuildsSliceState } from './interfaces';

interface FilterProps {
    showOnlyValues?: boolean;
}

export const filters: { [id: string]: (props: FilterProps) => ReactElement } = {
    [BuildsColumnId.projectName]: ProjectNameSelector,
    [BuildsColumnId.status]: StatusSelector,
    [BuildsColumnId.branch]: BranchSelector,
    [BuildsColumnId.revision]: RevisionSelector,
    [BuildsColumnId.assetRevision]: AssetRevisionSelector,
    [BuildsColumnId.versionName]: VersionSelector,
    [BuildsColumnId.platform]: PlatformSelector,
    [BuildsColumnId.type]: TypeSelector,
    [BuildsColumnId.kind]: KindSelector,
    [BuildsColumnId.buildAgent]: BuildAgentSelector,
    [BuildsColumnId.author]: AuthorSelector,
};

export default function BuildsFilters(): ReactElement | null {
    const hasFilters = !!useSelector(selectAmountOfActiveBuildsFilters);
    const columns = useSelector(selectBuildsColumns);

    return (
        <CollapseFilters in={hasFilters} data-testid='builds-filters'>
            <FiltersContainer>
                {columns
                    .filter((column) => filters[column.id])
                    .map((column): ReactElement => {
                        const Filter = filters[column.id];
                        return <Filter key={column.id} showOnlyValues />;
                    })}
                <ClearAllFiltersButton />
            </FiltersContainer>
        </CollapseFilters>
    );
}

function ClearAllFiltersButton(): ReactElement {
    const dispatch = useDispatch<AppThunkDispatch<IBuildsSliceState>>();

    const handleClearClick = () => {
        dispatch(clearAllFilters());
        dispatch(getBuilds()).then(() => {
            dispatch(getBuildsExtras());
            dispatch(getBuildActions());
        });
    };

    return <ClearFiltersButton onClick={handleClearClick} />;
}

export function ProjectNameSelector({ showOnlyValues }: FilterProps): ReactElement {
    const dispatch = useDispatch<AppThunkDispatch<IBuildsSliceState>>();
    const { selected, options, loading } = useSelector(selectFilterProjectName);
    const parsedOptions = parseFilterOptions(options || []);

    const onChange = (newValue: string[] | null) => {
        dispatch(setFilterProjectName(newValue));
        dispatch(getBuilds()).then(() => {
            dispatch(getBuildsExtras());
            dispatch(getBuildActions());
        });
    };

    return (
        <ColumnFilter
            id='projectName-selector'
            label='Project'
            selected={selected}
            options={parsedOptions}
            loading={!!loading}
            onChange={onChange}
            showOnlyValues={showOnlyValues}
        />
    );
}

export function StatusSelector({ showOnlyValues }: FilterProps): ReactElement {
    const dispatch = useDispatch<AppThunkDispatch<IBuildsSliceState>>();
    const { selected, options, loading } = useSelector(selectFilterStatus);
    const parsedOptions = parseFilterOptions(options || []);

    const onChange = (newValue: string[] | null) => {
        dispatch(setFilterStatus(newValue));
        dispatch(getBuilds()).then(() => {
            dispatch(getBuildsExtras());
            dispatch(getBuildActions());
        });
    };

    return (
        <ColumnFilter
            id='status-selector'
            label='Status'
            selected={selected}
            options={parsedOptions}
            loading={!!loading}
            onChange={onChange}
            showOnlyValues={showOnlyValues}
        />
    );
}

export function BranchSelector({ showOnlyValues }: FilterProps): ReactElement {
    const dispatch = useDispatch<AppThunkDispatch<IBuildsSliceState>>();
    const { selected, options, loading } = useSelector(selectFilterBranch);
    const parsedOptions = parseFilterOptions(options || []);

    const onChange = (newValue: string[] | null) => {
        dispatch(setFilterBranch(newValue));
        dispatch(getBuilds()).then(() => {
            dispatch(getBuildsExtras());
            dispatch(getBuildActions());
        });
    };

    return (
        <ColumnFilter
            id='branch-selector'
            label='Branch'
            selected={selected}
            options={parsedOptions}
            loading={!!loading}
            onChange={onChange}
            showOnlyValues={showOnlyValues}
        />
    );
}

export function RevisionSelector({ showOnlyValues }: FilterProps): ReactElement {
    const dispatch = useDispatch<AppThunkDispatch<IBuildsSliceState>>();
    const { selected, options, loading } = useSelector(selectFilterRevision);
    const parsedOptions = parseFilterOptions(options || []);
    const sortedOptions = sortNumberOptions(parsedOptions, 'desc');

    const onChange = (newValue: string[] | null) => {
        dispatch(setFilterRevision(newValue));
        dispatch(getBuilds()).then(() => {
            dispatch(getBuildsExtras());
            dispatch(getBuildActions());
        });
    };

    return (
        <ColumnFilter
            id='revision-selector'
            label='Revision'
            selected={selected}
            options={sortedOptions}
            loading={!!loading}
            onChange={onChange}
            showOnlyValues={showOnlyValues}
        />
    );
}

export function AssetRevisionSelector({ showOnlyValues }: FilterProps): ReactElement {
    const dispatch = useDispatch<AppThunkDispatch<IBuildsSliceState>>();
    const { selected, options, loading } = useSelector(selectFilterAssetRevision);
    const parsedOptions = parseFilterOptions(options || []);
    const sortedOptions = sortNumberOptions(parsedOptions, 'desc');

    const onChange = (newValue: string[] | null) => {
        dispatch(setFilterAssetRevision(newValue));
        dispatch(getBuilds()).then(() => {
            dispatch(getBuildsExtras());
            dispatch(getBuildActions());
        });
    };

    return (
        <ColumnFilter
            id='asset-revision-selector'
            label='Asset Revision'
            selected={selected}
            options={sortedOptions}
            loading={!!loading}
            onChange={onChange}
            showOnlyValues={showOnlyValues}
        />
    );
}

export function VersionSelector({ showOnlyValues }: FilterProps): ReactElement {
    const dispatch = useDispatch<AppThunkDispatch<IBuildsSliceState>>();
    const { selected, options, loading } = useSelector(selectFilterVersionName);
    const sortedOptions = sortVersionOptions((options as string[]) || [], 'desc');

    const onChange = (newValue: string[] | null) => {
        dispatch(setFilterVersionName(newValue));
        dispatch(getBuilds()).then(() => {
            dispatch(getBuildsExtras());
            dispatch(getBuildActions());
        });
    };

    return (
        <ColumnFilter
            id='version-selector'
            label='Version'
            selected={selected}
            options={sortedOptions}
            loading={!!loading}
            onChange={onChange}
            showOnlyValues={showOnlyValues}
        />
    );
}

export function PlatformSelector({ showOnlyValues }: FilterProps): ReactElement {
    const dispatch = useDispatch<AppThunkDispatch<IBuildsSliceState>>();
    const { selected, options, loading } = useSelector(selectFilterPlatform);
    const parsedOptions = parseFilterOptions(options || []);

    const onChange = (newValue: string[] | null) => {
        dispatch(setFilterPlatform(newValue));
        dispatch(getBuilds()).then(() => {
            dispatch(getBuildsExtras());
            dispatch(getBuildActions());
        });
    };

    return (
        <ColumnFilter
            id='platform-selector'
            label='Platform'
            selected={selected}
            options={parsedOptions}
            loading={!!loading}
            onChange={onChange}
            showOnlyValues={showOnlyValues}
        />
    );
}

export function TypeSelector({ showOnlyValues }: FilterProps): ReactElement {
    const dispatch = useDispatch<AppThunkDispatch<IBuildsSliceState>>();
    const { selected, options, loading } = useSelector(selectFilterType);
    const parsedOptions = parseFilterOptions(options || []);

    const onChange = (newValue: string[] | null) => {
        dispatch(setFilterType(newValue));
        dispatch(getBuilds()).then(() => {
            dispatch(getBuildsExtras());
            dispatch(getBuildActions());
        });
    };

    return (
        <ColumnFilter
            id='type-selector'
            label='Type'
            selected={selected}
            options={parsedOptions}
            loading={!!loading}
            onChange={onChange}
            showOnlyValues={showOnlyValues}
        />
    );
}

export function KindSelector({ showOnlyValues }: FilterProps): ReactElement {
    const dispatch = useDispatch<AppThunkDispatch<IBuildsSliceState>>();
    const { selected, options, loading } = useSelector(selectFilterKind);
    const parsedOptions = parseFilterOptions(options || []);

    const onChange = (newValue: string[] | null) => {
        dispatch(setFilterKind(newValue));
        dispatch(getBuilds()).then(() => {
            dispatch(getBuildsExtras());
            dispatch(getBuildActions());
        });
    };

    return (
        <ColumnFilter
            id='kind-selector'
            label='Kind'
            selected={selected}
            options={parsedOptions}
            loading={!!loading}
            onChange={onChange}
            showOnlyValues={showOnlyValues}
        />
    );
}

export function BuildAgentSelector({ showOnlyValues }: FilterProps): ReactElement {
    const dispatch = useDispatch<AppThunkDispatch<IBuildsSliceState>>();
    const { selected, options, loading } = useSelector(selectFilterBuildAgent);

    const sortedOptions = useMemo(
        () =>
            ((options as IBuildAgent[]) || [])
                .slice()
                .sort()
                .map((item) => ({ label: getBuildAgentName(item), value: item._id })),
        [options],
    );

    const onChange = (newValue: string[] | null) => {
        dispatch(setFilterBuildAgent(newValue));
        dispatch(getBuilds()).then(() => {
            dispatch(getBuildsExtras());
            dispatch(getBuildActions());
        });
    };

    return (
        <ColumnFilter
            id='build-agent-selector'
            label='Agent'
            selected={selected}
            options={sortedOptions}
            loading={!!loading}
            onChange={onChange}
            showOnlyValues={showOnlyValues}
        />
    );
}

export function AuthorSelector({ showOnlyValues }: FilterProps): ReactElement {
    const { user } = useAuthUser();
    const dispatch = useDispatch<AppThunkDispatch<IBuildsSliceState>>();
    const { selected, options: authorsList, loading } = useSelector(selectFilterAuthor);

    const sortedOptions = useMemo(() => {
        const currentUser = authorsList.find((item) => user?.email && item.email === user.email);
        const filteredOptions = authorsList
            .filter((item) => item.email !== user?.email)
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((item) => ({ label: item.name || item.email, value: item._id }));

        return currentUser
            ? [{ label: currentUser.name || currentUser.email, value: currentUser._id }, ...filteredOptions]
            : filteredOptions;
    }, [authorsList, user]);

    const onChange = (newValue: string[] | null) => {
        dispatch(setFilterAuthor(newValue || null));
        dispatch(getBuilds()).then(() => {
            dispatch(getBuildsExtras());
            dispatch(getBuildActions());
        });
    };

    return (
        <ColumnFilter
            id='author-selector'
            label='Ordered By'
            selected={selected}
            options={sortedOptions}
            loading={!!loading}
            onChange={onChange}
            showOnlyValues={showOnlyValues}
        />
    );
}
