import { ReactElement, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Check } from '@mui/icons-material';
import { Box, MenuItem } from '@mui/material';
import { Dialog, successToast, Typography } from '@tactileentertainment/core-designsystem';
import { AppThunkDispatch } from '../../../store';
import { executeAction } from '../../buildDetails/buildDetailsSlice';
import { IBuildDetailsSlice } from '../../buildDetails/interfaces';
import ConfigurationSettings from '../../configurationSettings/ConfigurationSettings';
import { getDefaultSettings } from '../../configurationSettings/getDefaultSettings';
import { IConfigurationSettings, ISelectedSettings, ISetting } from '../../configurationSettings/interfaces';
import updateSelectedSettings from '../../configurationSettings/updateSelectedSettings';
import { ActionTrigger, IConfigurationAction } from '../../newBuild/interfaces';
import { IBuild } from '../interfaces';

export function BuildAction({
    action,
    build,
    onClick,
}: {
    action: IConfigurationAction;
    build: IBuild;
    onClick: () => void;
}): ReactElement {
    const dispatch = useDispatch<AppThunkDispatch<IBuildDetailsSlice>>();
    const [openSettingsDialog, setOpenSettingsDialog] = useState<boolean>(false);
    const [selectedSettings, setSelectedSettings] = useState<ISelectedSettings>();
    const [error, setError] = useState<string | undefined>();

    const processAction = () => {
        if (!action.settings || !Object.keys(action.settings).length) {
            execute();
        } else {
            setError(undefined);
            setSelectedSettings(getDefaultSettings(action.settings, { build }));
            setOpenSettingsDialog(true);
        }
        onClick();
    };

    const execute = () => {
        dispatch(executeAction({ buildId: build._id, action, settings: selectedSettings })).then(({ payload }) => {
            if (payload.error?.message) {
                setError(payload.error?.message);
            } else {
                setOpenSettingsDialog(false);
                successToast(action.display_name);
            }
        });
    };

    const cancel = () => {
        setOpenSettingsDialog(false);
        onClick();
    };

    const setSetting = (setting: ISetting, value: string | string[] | boolean | null) => {
        setSelectedSettings(updateSelectedSettings(selectedSettings || {}, setting, value));
    };

    const isValid = validateSettings(action.settings, selectedSettings);

    return (
        <>
            <MenuItem onClick={processAction}>
                {action.trigger === ActionTrigger.automatic && build.automaticActions?.includes(action.name) ? (
                    <Box sx={{ lineHeight: 1.2 }}>
                        <Typography>{action.display_name}</Typography>
                        <Typography variant='caption' color='textSecondary'>
                            <Check fontSize='inherit' /> Automatically Executed
                        </Typography>
                    </Box>
                ) : (
                    action.display_name
                )}
            </MenuItem>
            {openSettingsDialog && (
                <Dialog
                    open={openSettingsDialog}
                    title={action.display_name}
                    variant='custom'
                    content={
                        <>
                            <ConfigurationSettings
                                settings={action.settings}
                                selectedSettings={selectedSettings}
                                onSettingChange={setSetting}
                            />
                            {error && <Typography color='error'>{error}</Typography>}
                        </>
                    }
                    primaryButton={{
                        text: 'Execute',
                        onClick: execute,
                        disabled: !isValid,
                    }}
                    onClose={cancel}
                />
            )}
        </>
    );
}

function validateSettings(settings?: IConfigurationSettings, selectedSettings?: ISelectedSettings): boolean {
    if (!settings) {
        return true;
    }
    for (const [settingKey, setting] of Object.entries(settings)) {
        if (setting.required && selectedSettings?.[settingKey] === undefined) {
            return false;
        }
    }
    return true;
}
