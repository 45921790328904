import { ReactElement, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import SortIcon from '@mui/icons-material/Sort';
import { Box } from '@mui/material';
import { Dialog, Button } from '@tactileentertainment/core-designsystem';
import { AppThunkDispatch } from '../../store';
import { useGetProjectBranchesQuery } from '../buildAgents/buildAgentsApi';
import { selectChangeLogDialog, setChangeLogOpen } from '../buildDetails/buildDetailsSlice';
import { compareRevisions } from '../commons/utils/compareRevisions';
import { compareVersions } from '../commons/utils/compareVersions';
import { IKnownRevisions } from '../newBuild/interfaces';
import { useGetProjectsQuery } from '../projects/projectsApi';
import { useGetLogsQuery } from './changeLogApi';
import { ChangeLogInfoBox } from './ChangeLogInfoBox';
import { GameAndBranchSelector } from './GameAndBranchSelector';
import { EChangeLogSortType } from './interfaces/EChangeLogSortType';
import { LogsTable } from './LogsTable';
import { filterRealeaseBranches } from './utils/filterReleaseBranches';

export default function ChangeLogDialog({
    buildBranch,
    revision,
}: {
    buildBranch?: string;
    revision?: string;
}): ReactElement {
    const dispatch = useDispatch<AppThunkDispatch>();
    const { open } = useSelector(selectChangeLogDialog);
    const onClose = () => dispatch(setChangeLogOpen({ open: false }));

    return (
        <Dialog
            open={open}
            title='Change Log Viewer'
            variant='custom'
            onClose={onClose}
            content={
                <>
                    <ChangeLogActions buildBranch={buildBranch} revision={revision} />
                </>
            }
        />
    );
}

function ChangeLogActions({ buildBranch, revision }: { buildBranch?: string; revision?: string }): ReactElement {
    const { projectName } = useParams<{ projectName: string }>();
    const [selectedProject, setSelectedProject] = useState<string | undefined>(projectName);
    const [selectedBranch, setSelectedBranch] = useState<string | undefined>(buildBranch);
    const [filteredSortedBranches, setBranches] = useState<string[]>();
    const [logs, setLogs] = useState<IKnownRevisions[]>();
    const [sortingType, setSortingType] = useState<EChangeLogSortType>(EChangeLogSortType.commit);

    const { data: projects, isLoading: projectsLoading } = useGetProjectsQuery();
    const { data: branches, isLoading: branchesLoading } = useGetProjectBranchesQuery(
        { projectName: selectedProject as string },
        {
            skip: !selectedProject && !projectName,
        },
    );

    const { data: unfilteredLogs, isFetching } = useGetLogsQuery(
        {
            projectName: selectedProject as string,
            branch: selectedBranch as string,
            lastRevision: revision as string,
        },
        {
            skip: !selectedBranch,
        },
    );

    const sortLogs = () => {
        if (logs?.length && sortingType === EChangeLogSortType.commit) {
            const logsClone = structuredClone(logs);
            setLogs(logsClone.sort((logA, logB) => compareRevisions(logA.revision, logB.revision)));
            setSortingType(EChangeLogSortType.revision);
        }
        if (logs?.length && sortingType === EChangeLogSortType.revision) {
            const logsClone = structuredClone(logs);
            setLogs(logsClone.sort((logA, logB) => logA.commitMessage.localeCompare(logB.commitMessage)));
            setSortingType(EChangeLogSortType.commit);
        }
    };

    const copyToClipboard = () => {
        if (logs?.length) {
            let textToCopy = '';
            for (const log of logs) {
                textToCopy += log.commitMessage + '\n';
            }
            navigator.clipboard.writeText(textToCopy);
        }
    };

    useEffect(() => {
        setLogs([]);
    }, [selectedBranch]);

    useEffect(() => {
        if (branches) {
            const filtered = filterRealeaseBranches(branches);
            const sorted = filtered.sort(compareVersions);
            setBranches(sorted);
        }
    }, [branches]);

    useEffect(() => {
        if (unfilteredLogs) {
            setLogs(unfilteredLogs);
        }
    }, [unfilteredLogs]);

    useEffect(() => {
        if (buildBranch && filteredSortedBranches) {
            const startingBranch = filteredSortedBranches.find((branch) => branch === buildBranch);
            setSelectedBranch(startingBranch);
        }
    }, [filteredSortedBranches, buildBranch]);

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Box display='flex' gap={1} sx={{ width: '80%' }}>
                <GameAndBranchSelector
                    projects={projects?.map((proj) => proj.name) || []}
                    onChangeProject={setSelectedProject}
                    selectedProject={selectedProject}
                    projectsLoading={projectsLoading}
                    branches={filteredSortedBranches || []}
                    onChangeBranch={setSelectedBranch}
                    branchesLoading={branchesLoading}
                    selectedBranch={selectedBranch}
                    isProjectDisabled
                    isBranchDisabled
                />
                <Button
                    color='secondary'
                    title={`Sort by ${sortingType === EChangeLogSortType.commit ? EChangeLogSortType.revision : EChangeLogSortType.commit}`}
                    onClick={sortLogs}
                >
                    <SortIcon />
                </Button>
                <Button color='secondary' title='Copy commit messages to clipboard' onClick={copyToClipboard}>
                    <ContentCopyIcon />
                </Button>
            </Box>
            <Box sx={{ display: 'flex', m: 2, alignItems: 'center' }}>
                <ChangeLogInfoBox branch={buildBranch} />
            </Box>
            <Box sx={{ display: 'flex', ml: 2 }}>
                <LogsTable logs={logs} clearLogs={Boolean(!selectedBranch)} logsLoading={isFetching} />
            </Box>
        </Box>
    );
}
