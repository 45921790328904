import { ReactElement } from 'react';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Box, Chip, Link, Skeleton, TableCell, TableRow, Typography } from '@mui/material';
import { blue, CenteredFlexBox, red } from '@tactileentertainment/core-designsystem';
import ClickableTableRow from '../../commons/ClickableTableRow';
import GameModuleStatus from '../GameModuleStatus';
import { IModuleProject } from '../interfaces';
import { isSha } from '../utils';

interface GameModuleRowProps {
    name: string;
    redirectUrl?: string;
    currentProject: IModuleProject | null;
    previousProjectRelease?: IModuleProject | null;
    homepage?: string;
    showPrevReleaseColumn?: boolean;
    isLoading?: boolean;
    isPrimary?: boolean;
    isDeprecated?: boolean;
}

export default function GameModuleProjectRow({ redirectUrl, ...props }: GameModuleRowProps): ReactElement {
    return redirectUrl ? (
        <ClickableTableRow redirectUrl={redirectUrl}>
            <RowContent {...props} />
        </ClickableTableRow>
    ) : (
        <TableRow>
            <RowContent {...props} />
        </TableRow>
    );
}

function RowContent({
    name,
    currentProject,
    previousProjectRelease,
    homepage,
    showPrevReleaseColumn = false,
    isLoading = true,
    isPrimary = false,
    isDeprecated = false,
}: GameModuleRowProps): ReactElement {
    const project = currentProject ?? previousProjectRelease;
    const isBuiltIn = project?.latestVersion === 'builtin';

    return (
        <>
            <TableCell>
                <Box display='flex' alignItems='center'>
                    <Typography noWrap>{name}</Typography>
                    {homepage && (
                        <Link
                            target='_blank'
                            href={homepage}
                            onMouseUp={(event) => event.stopPropagation()}
                            sx={{ ml: 1, display: 'flex' }}
                        >
                            <OpenInNewIcon fontSize='small' />
                        </Link>
                    )}
                    {isDeprecated && (
                        <Chip
                            sx={{ ml: 1, background: red[200], color: red[700], width: '15%' }}
                            size='small'
                            label='Deprecated'
                        ></Chip>
                    )}
                    {isPrimary && (
                        <Chip
                            sx={{
                                ml: 1,
                                background: blue[200],
                                color: blue[700],
                                width: '15%',
                            }}
                            size='small'
                            label='Primary'
                        ></Chip>
                    )}
                </Box>
            </TableCell>
            <TableCell>
                <Typography noWrap>{project?.branch || '--'}</Typography>
            </TableCell>
            <TableCell>
                <Typography noWrap color={isBuiltIn ? 'textSecondary' : 'default'}>
                    {isBuiltIn ? 'Built-in' : project?.latestVersion ?? ''}
                </Typography>
            </TableCell>
            {showPrevReleaseColumn && (
                <TableCell>
                    {isLoading && <Skeleton />}
                    {previousProjectRelease !== undefined && (
                        <ProjectVersionWithStatus project={previousProjectRelease} />
                    )}
                </TableCell>
            )}
            <TableCell>
                {project && (
                    <ProjectVersionWithStatus project={currentProject === undefined ? project : currentProject} />
                )}
            </TableCell>
        </>
    );
}

interface ProjectVersionWithStatusProps {
    project: IModuleProject | null;
}

export const ProjectVersionWithStatus = ({ project }: ProjectVersionWithStatusProps) => {
    if (!project) {
        return <>--</>;
    }

    const { version, homepage } = project;
    const isProjectVersionSha = isSha(project?.version ?? '');
    return (
        <CenteredFlexBox sx={{ justifyContent: 'flex-start' }}>
            <ProjectVersion version={version} homepage={homepage} isProjectVersionSha={isProjectVersionSha} />
            <GameModuleStatus project={project as IModuleProject} />
        </CenteredFlexBox>
    );
};

export const ProjectVersion = ({
    isProjectVersionSha,
    version,
    homepage,
}: {
    isProjectVersionSha: boolean;
    version?: string;
    homepage?: string;
}) => {
    return (
        <Typography
            component={isProjectVersionSha ? 'a' : 'p'}
            noWrap
            {...(isProjectVersionSha && {
                href: `${homepage}/commits/${version}`,
                target: '_blank',
                rel: 'noreferrer',
                onMouseUp: (event: any) => {
                    event.stopPropagation();
                },
            })}
            sx={{ mr: 1, cursor: isProjectVersionSha ? 'pointer' : 'default', textDecoration: 'none' }}
        >
            {version}
        </Typography>
    );
};
