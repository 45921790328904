import { ReactElement } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormControlLabel, Switch } from '@mui/material';
import { AppThunkDispatch } from '../../store';
import BuildsSearch from './BuildsSearch';
import {
    getBuilds,
    getBuildsExtras,
    setFilterReleaseCandidate,
    selectFilterReleaseCandidate,
    getBuildActions,
} from './buildsSlice';
import { IBuildsSliceState } from './interfaces';

export default function BuildFilters(): ReactElement {
    const dispatch = useDispatch<AppThunkDispatch<IBuildsSliceState>>();
    const releaseCandidate = useSelector(selectFilterReleaseCandidate);

    const handleReleaseCandidateChange = () => {
        dispatch(setFilterReleaseCandidate(!releaseCandidate.selected));
        dispatch(getBuilds()).then(() => {
            dispatch(getBuildsExtras());
            dispatch(getBuildActions());
        });
    };

    return (
        <>
            <BuildsSearch />
            <FormControlLabel
                sx={{ ml: '-6px' }}
                control={
                    <Switch
                        checked={!!releaseCandidate.selected}
                        onChange={handleReleaseCandidateChange}
                        color='primary'
                    />
                }
                label='Show Only Release Candidates'
            />
        </>
    );
}
