import { ReactElement, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Box, Table, TableCell, TableContainer, TableHead, useTheme } from '@mui/material';
import { Switch } from '@tactileentertainment/core-designsystem';
import { selectBuildDetailsBuild } from '../../buildDetails/buildDetailsSlice';
import { useGetPreviousReleaseQuery } from '../../builds/buildsApi';
import NoResultsFound from '../../commons/NoResultsFound';
import TableBody from '../../commons/TableBody';
import TableHeaderRow from '../../commons/TableHeaderRow';
import GameModuleProjectRow from '../details/GameModuleProjectRow';
import { GameModuleCategory } from '../enums';
import { useGetGameModulesQuery, useLazyCompareGameModulesQuery } from '../gameModulesApi';
import { IGameModules, IModuleProject } from '../interfaces';
import { filterModules } from '../utils';
import BuildGameModulesTableTabs from './BuildGameModulesTableTabs';

interface BuildGameModulesTableProps {
    projectModules: IModuleProject[] | undefined;
    category: GameModuleCategory;
    onCategoryChange: (category: GameModuleCategory) => void;
}

export default function BuildGameModulesTable({
    projectModules = [],
    category,
    onCategoryChange,
}: BuildGameModulesTableProps): ReactElement {
    const theme = useTheme();
    const build = useSelector(selectBuildDetailsBuild);
    const [modules, setModules] = useState<Array<IModuleProject | null>>(projectModules);
    const [currentPreviousModules, setCurrentPreviousModules] = useState<IGameModules>();
    const [prevReleaseModules, setPrevReleaseModules] = useState<Array<IModuleProject | null>>([]);
    const { data: prevReleaseData } = useGetPreviousReleaseQuery(
        {
            projectName: build?.projectName ?? '',
            buildNumber: build?.buildNumber ?? '',
            versionName: build?.versionName ?? '',
            platform: build?.platform ?? '',
        },
        { skip: !build },
    );
    const { data: allModules } = useGetGameModulesQuery({ includeFields: [] });
    const [compareWithPrevRelease, setCompareWithPrevRelease] = useState(false);

    const [getModulesForComparison, { isLoading, isFetching }] = useLazyCompareGameModulesQuery();

    const setModulesForCategory = useCallback(
        (gameModules: IGameModules) => {
            const comparedModulesWithChanges = filterModules({
                modules: gameModules,
                searchText: '',
                hideModules: false,
                primary: false,
                deprecated: false,
                showOnlyNonUpToDate: false,
                branchesOnly: false,
                selectedProjects: [],
                category,
                showOnlyDiff: true,
            });

            const [currentModuleBuilds, prevReleaseModuleBuilds] = comparedModulesWithChanges
                .filter((mod) => mod.projects[0] || mod.projects[1])
                .sort((a, b) => {
                    const projectAModuleName = a.projects[0]?.moduleName ?? a.projects[1]?.moduleName ?? '';
                    const projectBModuleName = b.projects[0]?.moduleName ?? b.projects[1]?.moduleName ?? '';
                    return projectAModuleName.localeCompare(projectBModuleName);
                })
                .reduce(
                    (acc, mod) => {
                        return [
                            [...acc[0], mod.projects[0]],
                            [...acc[1], mod.projects[1]],
                        ];
                    },
                    [[], []] as [Array<IModuleProject | null>, Array<IModuleProject | null>],
                );

            setModules(currentModuleBuilds);
            setPrevReleaseModules(prevReleaseModuleBuilds);
        },
        [category],
    );

    useEffect(() => {
        setModules(projectModules);
    }, [projectModules]);

    useEffect(() => {
        if (compareWithPrevRelease && currentPreviousModules) {
            setModulesForCategory(currentPreviousModules);
        }
    }, [category, compareWithPrevRelease, currentPreviousModules, setModulesForCategory]);

    const handlePrevReleaseChange = async (checked: boolean) => {
        setCompareWithPrevRelease(checked);
        if (checked) {
            if (!currentPreviousModules && build && prevReleaseData) {
                const { data: comparedModules } = await getModulesForComparison({
                    projectA: build.projectName,
                    projectB: build.projectName,
                    branchA: build.branch,
                    branchB: prevReleaseData?.branch,
                    platform: build.platform,
                });

                if (comparedModules) {
                    setCurrentPreviousModules(comparedModules.modules);
                    setModulesForCategory(comparedModules.modules);
                }
            }
        } else {
            setModules(projectModules);
        }
    };

    return (
        <>
            <Box display='flex' alignItems='center' gap={4} pb={2}>
                <BuildGameModulesTableTabs onCategoryChange={onCategoryChange} category={category} />
                <Switch
                    checked={compareWithPrevRelease}
                    label={`Compare with previous release${prevReleaseData?.versionName ? ` (${prevReleaseData.versionName})` : ''}`}
                    onChange={(_, checked) => handlePrevReleaseChange(checked)}
                    disabled={!prevReleaseData?.branch}
                />
            </Box>
            <TableContainer
                sx={{
                    '.MuiTableCell-root': { fontWeight: 500 },
                    background: (theme) => theme.palette.common.white,
                    [theme.breakpoints.up('sm')]: {
                        height: 'calc(100vh - 410px)',
                    },
                }}
            >
                {allModules ? (
                    <Table
                        stickyHeader
                        aria-label='build game modules table'
                        style={{ width: '100%', minWidth: 1000, tableLayout: 'fixed' }}
                    >
                        <TableHead data-testid='build-game-modules-table-head'>
                            <TableHeaderRow>
                                <TableCell key='name' sx={{ width: 300 }}>
                                    {category === GameModuleCategory.MODULE && 'Module Name'}
                                    {category === GameModuleCategory.PACKAGE && 'Package Name'}
                                    {category === GameModuleCategory.UNITY_PACKAGE && 'Unity Package Name'}
                                </TableCell>
                                <TableCell key='branch' sx={{ width: 100 }}>
                                    Branch
                                </TableCell>
                                <TableCell key='latest-version' sx={{ width: 100 }}>
                                    Latest Version
                                </TableCell>
                                {compareWithPrevRelease && (
                                    <TableCell key='previous-release' sx={{ width: 100 }}>
                                        {`Previous Release${prevReleaseData?.versionName ? ` (${prevReleaseData.versionName})` : ''}`}
                                    </TableCell>
                                )}
                                <TableCell key='current-version' sx={{ width: 100 }}>
                                    {`Current Build${build?.versionName ? ` (${build.versionName})` : ''}`}
                                </TableCell>
                            </TableHeaderRow>
                        </TableHead>

                        <TableBody data-testid='build-game-modules-table-body'>
                            {modules.length > 0
                                ? modules.map((module, index) => {
                                      const existingModule = module ?? prevReleaseModules[index];
                                      const current = allModules.modules[category].find(
                                          (mod) => mod.moduleName === existingModule?.moduleName,
                                      );

                                      return (
                                          <GameModuleProjectRow
                                              key={existingModule?.moduleName}
                                              showPrevReleaseColumn={compareWithPrevRelease}
                                              currentProject={module}
                                              previousProjectRelease={prevReleaseModules[index]}
                                              isLoading={isLoading || isFetching}
                                              name={existingModule?.moduleName ?? ''}
                                              redirectUrl={
                                                  existingModule
                                                      ? `/modules/${encodeURIComponent(existingModule.moduleName)}`
                                                      : undefined
                                              }
                                              homepage={existingModule?.homepage}
                                              isPrimary={current?.primary}
                                              isDeprecated={current?.deprecated}
                                          />
                                      );
                                  })
                                : null}
                        </TableBody>
                    </Table>
                ) : null}
                {compareWithPrevRelease && !modules.length && (
                    <NoResultsFound text='No changes found' sx={{ width: '100%' }} />
                )}
            </TableContainer>
        </>
    );
}
