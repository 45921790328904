import React, { ReactElement, useEffect, useState } from 'react';
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    IconButton,
} from '@mui/material';
import { blueGray, indigo } from '@tactileentertainment/core-designsystem';
import { SVGIcon, SVGIcons } from '../../assets/SVGIcon';
import { SlackRecipients } from '../commons/SlackRecipients';
import { useGetCurrentUserQuery } from '../userProfile/usersApi';
import { ISubscriptionEvent } from './interfaces';
import { useGetSubscriptionsQuery, useSubscribeMutation, useRemoveSubscriptionsMutation } from './subscriptionsApi';

export default function SubscribeButton({ event }: { event: ISubscriptionEvent }): ReactElement {
    const { data: userData } = useGetCurrentUserQuery();
    const { data: currentSubscriptions, refetch } = useGetSubscriptionsQuery({ type: 'slack', event });
    const [subscribe] = useSubscribeMutation();
    const [removeSubscriptions] = useRemoveSubscriptionsMutation();
    const [openDialog, setOpenDialog] = useState<boolean>(false);
    const [selfSubscribed, setSelfSubscribed] = useState<boolean>(false);
    const [selectedSubscribers, setSelectedSubscribers] = useState<string[]>([]);

    useEffect(() => {
        if (currentSubscriptions) {
            const { email, preferences } = userData?.user || {};
            const selfSubscription = currentSubscriptions.find(
                (subscription) => subscription.subscriber === email || subscription.subscriber === preferences?.slackId,
            );
            setSelectedSubscribers(
                currentSubscriptions
                    .filter((subscription) => subscription !== selfSubscription)
                    .map((subscription) => subscription.subscriber),
            );
            setSelfSubscribed(selfSubscription !== undefined);
        }
    }, [openDialog, currentSubscriptions, userData]);

    const cancel = () => {
        setOpenDialog(false);
    };

    const subscribeToResults = () => {
        const subscribersList = [...selectedSubscribers];
        if (selfSubscribed && userData?.user?.email) {
            subscribersList.push(userData?.user?.email);
        }

        const toSubscribe = subscribersList.filter(
            (subscriber) => !currentSubscriptions?.find((subscription) => subscription.subscriber === subscriber),
        );
        const toRemove = currentSubscriptions?.filter(
            (subscription) => !subscribersList.includes(subscription.subscriber),
        );

        if (toSubscribe.length || toRemove?.length) {
            Promise.all([
                toSubscribe?.length ? subscribe({ type: 'slack', event, subscribers: toSubscribe }) : undefined,
                toRemove?.length ? removeSubscriptions(toRemove.map((subscription) => subscription._id)) : undefined,
            ]).then(refetch);
        }

        setOpenDialog(false);
    };

    return (
        <>
            <IconButton
                title='Notifications'
                onClick={() => setOpenDialog(true)}
                sx={{ color: currentSubscriptions?.length ? indigo[700] : blueGray[800] }}
            >
                <SVGIcon icon={SVGIcons.slack} />
            </IconButton>
            {openDialog && (
                <Dialog
                    open={openDialog}
                    maxWidth='sm'
                    fullWidth
                    onClose={cancel}
                    onKeyDown={(event) => {
                        if (event.key === 'Enter' && event.metaKey) {
                            subscribeToResults();
                        } else if (event.key === 'Escape') {
                            cancel();
                        }
                    }}
                >
                    <DialogTitle>Notifications</DialogTitle>
                    <DialogContent>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    color='primary'
                                    size='small'
                                    checked={selfSubscribed}
                                    onChange={(event: React.ChangeEvent<any>) =>
                                        setSelfSubscribed(event.target.checked)
                                    }
                                />
                            }
                            label='Notify me when result is ready'
                        />
                        <SlackRecipients
                            selectedSubscribers={selectedSubscribers}
                            setSelectedSubscribers={setSelectedSubscribers}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={cancel}>Cancel</Button>
                        <Button autoFocus color='primary' onClick={subscribeToResults}>
                            Done
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </>
    );
}
