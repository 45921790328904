import { ReactElement } from 'react';
import { Box, Divider, Paper, styled } from '@mui/material';
import AcceleratorSelector from '../../unityAccelerators/AcceleratorSelector';
import { IProjectDraft } from '../interfaces';
import AgentsSelectorTable from './AgentsSelectorTable';
import AvailabilitySelector from './AvailabilitySelector';
import { DefaultNotificationChannel } from './DefaultNotificationChannel';
import DiskSpaceRequirementInput from './DiskSpaceRequirementInput';
import ProjectNameInput from './ProjectNameInput';
import SVNPathInput from './SVNPathInput';

const FormBox = styled(Box)(({ theme }) => ({
    '& > div': {
        minWidth: 350,
        marginRight: theme.spacing(3),
    },
    [theme.breakpoints.down('sm')]: {
        '& > div': {
            width: '100%',
            marginTop: theme.spacing(1),
            marginRight: 0,
        },
    },
}));

interface EditProjectContentProps {
    projectDraft: IProjectDraft;
    onChange: (update: Partial<IProjectDraft>) => void;
}

export default function EditProjectContent({ projectDraft, onChange }: EditProjectContentProps): ReactElement {
    return (
        <Paper sx={{ py: 3, mx: 4 }}>
            <FormBox sx={{ display: 'flex', flexWrap: 'wrap', mx: 3, gap: 2 }}>
                <ProjectNameInput projectName={projectDraft.name} onChange={(name) => onChange({ name })} />
                <DiskSpaceRequirementInput
                    spaceRequirement={projectDraft.minDiskSpaceRequirement || 0}
                    onChange={(value) => onChange({ minDiskSpaceRequirement: value })}
                />
                <DefaultNotificationChannel
                    channel={projectDraft.defaultNotificationChannel}
                    onChange={(value) => onChange({ defaultNotificationChannel: value })}
                />
                <SVNPathInput svnPath={projectDraft.svnPath} onChange={(svnPath) => onChange({ svnPath })} />
                <AcceleratorSelector
                    accelerator={projectDraft.accelerator}
                    onAcceleratorSelected={(accelerator) => onChange({ accelerator })}
                />
                <AvailabilitySelector
                    checked={projectDraft.isOrderable}
                    onChange={(isOrderable) => onChange({ isOrderable })}
                    style={{ minWidth: 200 }}
                />
            </FormBox>
            <Divider sx={{ mt: 3 }} />

            <AgentsSelectorTable
                style={{ marginTop: '16px', maxHeight: 'calc(100vh - 385px)' }}
                selectedAgents={projectDraft.agents}
                onChange={(agents) => onChange({ agents })}
            />
        </Paper>
    );
}
