import React, { ReactElement, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import { Button } from '@mui/material';
import BuildActionsMenu from '../commons/buildAction/BuildActionsMenu';
import { EBuildStatus } from '../commons/enums';
import { IBuild } from '../commons/interfaces';
import { matchAction } from '../commons/utils/matchAction';
import { IConfigurationAction } from '../newBuild/interfaces';
import { selectBuildDetailsActions, selectBuildDetailsResult, selectBuildStatus } from './buildDetailsSlice';

export default function BuildActions({ build }: { build: IBuild }): ReactElement | null {
    const buildStatus = useSelector(selectBuildStatus);
    const buildResult = useSelector(selectBuildDetailsResult);
    const buildActions = useSelector(selectBuildDetailsActions);

    const [anchorEl, setAnchorEl] = useState<Element | null>(null);

    const openMenu = (event: React.MouseEvent) => {
        setAnchorEl(event.currentTarget);
    };

    const closeMenu = () => {
        setAnchorEl(null);
    };

    const availableActions: IConfigurationAction[] | undefined = useMemo(() => {
        const buildState = { ...build, status: buildStatus as EBuildStatus };
        if (!buildActions) {
            return [];
        }
        return buildActions.filter((action) => matchAction(buildState, action, buildResult));
    }, [build, buildResult, buildStatus, buildActions]);

    if (!availableActions?.length) {
        return null;
    }

    return (
        <>
            <Button
                variant='outlined'
                color='secondary'
                endIcon={anchorEl ? <ArrowDropUp fontSize='small' /> : <ArrowDropDown fontSize='small' />}
                onClick={openMenu}
                sx={{ mr: 1.5, py: 1.25, pl: 2, my: 1 }}
            >
                Actions
            </Button>
            <BuildActionsMenu
                anchorEl={anchorEl}
                closeMenu={closeMenu}
                availableActions={availableActions}
                build={build}
            />
        </>
    );
}
