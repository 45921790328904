import { IToggleColumn } from '../commons/interfaces';
import { getInitialColumnVisibilityState } from '../commons/toggleableColumnsState';

export enum ProjectsColumnId {
    project = 'project',
    svnPath = 'svnPath',
    isEnabled = 'isEnabled',
    agents = 'agents',
    managers = 'managers',
    isOrderable = 'isOrderable',
    unityVersion = 'unityVersion',
}

const defaultColumnVisibility = {
    [ProjectsColumnId.project]: true,
    [ProjectsColumnId.svnPath]: true,
    [ProjectsColumnId.unityVersion]: true,
    [ProjectsColumnId.isEnabled]: true,
    [ProjectsColumnId.agents]: true,
    [ProjectsColumnId.managers]: true,
    [ProjectsColumnId.isOrderable]: true,
};

export const COOKIE_NAME = 'projectsVisibleColumns';
const columnInitialVisibleState = getInitialColumnVisibilityState(COOKIE_NAME, defaultColumnVisibility);
export const columns: Array<IToggleColumn<ProjectsColumnId>> = [
    {
        id: ProjectsColumnId.isEnabled,
        label: 'Status',
        align: 'left',
        width: '15%',
        visible: columnInitialVisibleState[ProjectsColumnId.isEnabled],
    },
    {
        id: ProjectsColumnId.project,
        label: 'Project',
        align: 'left',
        width: '20%',
        visible: columnInitialVisibleState[ProjectsColumnId.project],
    },
    {
        id: ProjectsColumnId.svnPath,
        label: 'SVN Path',
        align: 'left',
        width: '20%',
        visible: columnInitialVisibleState[ProjectsColumnId.svnPath],
    },
    {
        id: ProjectsColumnId.unityVersion,
        label: 'Unity Version',
        align: 'left',
        width: '10%',
        visible: columnInitialVisibleState[ProjectsColumnId.unityVersion],
    },
    {
        id: ProjectsColumnId.agents,
        label: 'Agents Available',
        align: 'center',
        width: '10%',
        visible: columnInitialVisibleState[ProjectsColumnId.agents],
    },
    {
        id: ProjectsColumnId.managers,
        label: 'Managers',
        align: 'left',
        width: '15%',
        visible: columnInitialVisibleState[ProjectsColumnId.managers],
    },
    {
        id: ProjectsColumnId.isOrderable,
        label: 'Available for Ordering',
        align: 'center',
        width: '15%',
        visible: columnInitialVisibleState[ProjectsColumnId.isOrderable],
    },
];

export const mobileColumns: ProjectsColumnId[] = [
    ProjectsColumnId.project,
    ProjectsColumnId.svnPath,
    ProjectsColumnId.isEnabled,
    ProjectsColumnId.agents,
    ProjectsColumnId.isOrderable,
];
