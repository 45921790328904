export enum AppBarBuildCategories {
    builds = 'build',
    bundle = 'bundle',
    cache = 'cache',
    custom = 'custom',
    patch = 'patch',
    others = 'others',
}

export const defaultCategories = [
    AppBarBuildCategories.builds,
    AppBarBuildCategories.bundle,
    AppBarBuildCategories.cache,
    AppBarBuildCategories.patch,
    AppBarBuildCategories.custom,
];
