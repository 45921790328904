import { ReactElement } from 'react';
import { Box } from '@mui/material';
import { Alert, Typography } from '@tactileentertainment/core-designsystem';
import { IConfigurationBuild } from './interfaces';

interface NewBuildValidationErrorsProps {
    validationErrors: string[];
    selectedBuilds?: IConfigurationBuild[];
}

export default function NewBuildValidationErrors(props: NewBuildValidationErrorsProps): ReactElement {
    const { validationErrors, selectedBuilds } = props;
    const buildsWithoutAvailableAgents = (selectedBuilds || [])?.filter((build) => build.viableAgentsCount === 0);
    const hasError = buildsWithoutAvailableAgents.length > 0 || validationErrors.length > 0;

    if (!hasError) {
        return <></>;
    }

    const invalidBuildNames = buildsWithoutAvailableAgents.map((build) => build.display_name).join(', ');

    return (
        <Box sx={{ mb: 3, gap: 2 }}>
            {validationErrors.map((error, key) => (
                <Alert key={key} variant='text' severity='info'>
                    {error}
                </Alert>
            ))}

            {buildsWithoutAvailableAgents.length > 0 && (
                <Alert key={invalidBuildNames} variant='text' severity='error'>
                    <strong>There was a problem with that action</strong>

                    <Typography color='error'>
                        You have most likely requested a specific version of Xcode or Unity, that is no longer installed
                        on any of the build server agents.
                    </Typography>

                    <Typography color='error'>
                        You should check which requirements you have specified in your projects <b>build_config.json</b>{' '}
                        and adjust them, so that they can be satisfied by a build agent.
                    </Typography>
                </Alert>
            )}
        </Box>
    );
}
