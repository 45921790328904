import { ReactElement, useEffect, lazy, Suspense } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableHead,
    CircularProgress,
    Fab,
    IconButton,
    useMediaQuery,
    useTheme,
    Zoom,
    Paper,
} from '@mui/material';
import { AppThunkDispatch } from '../../store';
import { IBuildResult } from '../buildDetails/interfaces';
import BuildActionConfirmationDialog from '../commons/buildAction/BuildActionConfirmationDialog';
import { SliceStatus } from '../commons/enums';
import HeaderSection from '../commons/HeaderSection';
import { IBuild } from '../commons/interfaces';
import NoResultsFound from '../commons/NoResultsFound';
import Page from '../commons/Page';
import TableContainer from '../commons/TableContainer';
import TableHeaderRow from '../commons/TableHeaderRow';
import TablePagination from '../commons/TablePagination';
import { openSocket } from '../sockets';
import BuildActionBox from './BuildActionBox';
import BuildFilters from './BuildFilters';
import { filters as buildsFilters } from './BuildsFilters';
import {
    getBuilds,
    getAvailableOptions,
    getBuildsExtras,
    selectAllBuilds,
    selectBuildsStatus,
    selectTableSize,
    selectBuildsPage,
    setBuildsPage,
    selectBuildsTotalCount,
    selectBuildsColumns,
    addNewBuild,
    updateBuild,
    updateBuildExtras,
    setFilters,
    getBuildActions,
} from './buildsSlice';
import { IBuildsSliceState } from './interfaces';

export const BuildRow = lazy(() => import('./BuildRow'));
export const BuildsFilters = lazy(() => import('./BuildsFilters'));
export const BuildLoadingRows = lazy(() => import('./BuildLoadingRows'));

const buildsLoader = (): ReactElement => {
    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }} data-testid='builds-loading'>
            <CircularProgress style={{ margin: '8px' }} size={20} />
        </div>
    );
};

export const tableMaxWidth = 1600;

export default function Builds(): ReactElement {
    const dispatch = useDispatch<AppThunkDispatch<IBuildsSliceState>>();
    const builds = useSelector(selectAllBuilds);
    const status = useSelector(selectBuildsStatus);
    const tableSize = useSelector(selectTableSize);
    const page = useSelector(selectBuildsPage);
    const totalCount = useSelector(selectBuildsTotalCount);
    const columns = useSelector(selectBuildsColumns);
    const isMobile = useMediaQuery('(max-width: 760px)');

    const theme = useTheme();
    const transitionDuration = {
        enter: theme.transitions.duration.enteringScreen,
        exit: theme.transitions.duration.leavingScreen,
    };

    const handleChangePage = (_event: unknown, newPage: number) => {
        dispatch(setBuildsPage(newPage));

        dispatch(getBuilds()).then(() => {
            dispatch(getBuildsExtras());
            dispatch(getBuildActions());
        });
    };

    useEffect(() => {
        dispatch(getAvailableOptions());
    }, [dispatch]);

    useEffect(() => {
        dispatch(setFilters());
        dispatch(getBuilds()).then(() => {
            dispatch(getBuildsExtras());
            dispatch(getBuildActions());
        });
    }, [dispatch]);

    useEffect(() => {
        function handleNewBuild({ build }: { build: IBuild }) {
            dispatch(addNewBuild(build));
        }
        function handleUpdateBuild({ build }: { build: IBuild }) {
            dispatch(updateBuild(build));
        }
        function handleUpdateBuildResult({ buildResult }: { buildResult: IBuildResult }) {
            if (buildResult) {
                dispatch(updateBuildExtras(buildResult));
            }
        }

        const socket = openSocket('builds');
        socket.on('new-build', handleNewBuild);
        socket.on('update-build', handleUpdateBuild);
        socket.on('update-build-result', handleUpdateBuildResult);

        return () => {
            socket.off('new-build', handleNewBuild);
            socket.off('update-build', handleUpdateBuild);
            socket.off('update-build-result', handleUpdateBuildResult);
        };
    }, [dispatch]);

    return (
        <Suspense fallback={buildsLoader()}>
            <Page>
                <HeaderSection
                    title='Builds'
                    isMobile={isMobile}
                    actionBox={<BuildActionBox />}
                    filters={<BuildFilters />}
                />
                <Paper
                    data-testid='builds'
                    sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, overflow: 'hidden' }}
                >
                    <BuildsFilters data-testid='builds-filters' />
                    <TableContainer sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Table
                            stickyHeader
                            aria-label='builds table'
                            size={tableSize}
                            sx={{ tableLayout: 'fixed', minWidth: tableMaxWidth }}
                        >
                            <TableHead data-testid='builds-table-head'>
                                <TableHeaderRow sx={{ '.MuiTableCell-root:first-of-type': { px: 0 } }}>
                                    <TableCell size='small' sx={{ width: '30px' }} />
                                    {columns.map((column) => (
                                        <TableCell
                                            key={column.id}
                                            align={column.align}
                                            size='small'
                                            sx={{
                                                width: column.width,
                                                minWidth: column.minWidth,
                                                display: column.visible ? undefined : 'none',
                                            }}
                                        >
                                            <Box>
                                                {column.label}
                                                {buildsFilters[column.id]?.({ showOnlyValues: false })}
                                            </Box>
                                        </TableCell>
                                    ))}
                                </TableHeaderRow>
                            </TableHead>
                            <TableBody data-testid='builds-table-body'>
                                {status === SliceStatus.loading ? (
                                    <BuildLoadingRows rows={10} />
                                ) : (
                                    builds.map((build) => <BuildRow key={build._id} build={build} />)
                                )}
                            </TableBody>
                        </Table>
                        {status === SliceStatus.loaded && !builds.length && (
                            <NoResultsFound text="Your search didn't return builds!" />
                        )}
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[]}
                        count={totalCount}
                        rowsPerPage={50}
                        page={page}
                        onPageChange={handleChangePage}
                        data-testid='builds-table-pagination'
                    />
                    <Zoom in={isMobile} timeout={transitionDuration} unmountOnExit>
                        <Fab
                            sx={{
                                margin: theme.spacing(1),
                                position: 'fixed',
                                bottom: theme.spacing(4),
                                right: theme.spacing(2),
                            }}
                            size='medium'
                            color='secondary'
                            aria-label='new build'
                            title='New Build'
                        >
                            <IconButton
                                component={Link}
                                to='/new-build'
                                color='inherit'
                                sx={{
                                    '&:hover': {
                                        transform: 'rotate(90deg)',
                                        transition: 'transform 0.5s',
                                    },
                                }}
                            >
                                <AddIcon />
                            </IconButton>
                        </Fab>
                    </Zoom>
                </Paper>
                <BuildActionConfirmationDialog />
            </Page>
        </Suspense>
    );
}
