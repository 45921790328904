import { Table, TableCell, TableHead, TableRow } from '@mui/material';
import { CenteredFlexBox, Typography } from '@tactileentertainment/core-designsystem';
import { getAcronym } from '../../commons/getAcronym';
import NoResultsFound from '../../commons/NoResultsFound';
import TactileTableBody from '../../commons/TableBody';
import TableContainer from '../../commons/TableContainer';
import TableHeaderRow from '../../commons/TableHeaderRow';
import { ProjectVersion, ProjectVersionWithStatus } from '../details/GameModuleProjectRow';
import { GameModuleCategory } from '../enums';
import { IGameModules } from '../interfaces';
import { isSha } from '../utils';

interface CompareModuleTableProps {
    modulesDiffData: IGameModules;
    tab: GameModuleCategory;
    primaryProject: string;
    secondaryProject: string;
    primaryBranch: string;
    secondaryBranch: string;
}

export const CompareModulesTable = ({
    modulesDiffData,
    tab,
    primaryProject,
    secondaryProject,
    primaryBranch,
    secondaryBranch,
}: CompareModuleTableProps) => {
    return modulesDiffData[tab].length > 0 ? (
        <TableContainer>
            <Table stickyHeader sx={{ tableLayout: 'fixed' }}>
                <TableHead sx={{ height: 44 }}>
                    <TableHeaderRow>
                        <TableCell>Module Name</TableCell>
                        <TableCell>Latest Version</TableCell>
                        <TableCell>{`${getAcronym(primaryProject)}/${primaryBranch || 'trunk'}`}</TableCell>
                        <TableCell>{`${getAcronym(secondaryProject)}/${secondaryBranch || 'trunk'}`}</TableCell>
                    </TableHeaderRow>
                </TableHead>
                <TactileTableBody>
                    {modulesDiffData[tab].map((module, i) => (
                        <TableRow key={i}>
                            <TableCell sx={{ width: '40%' }}>
                                <Typography variant='body-2'>{module.moduleName}</Typography>
                            </TableCell>
                            <TableCell>
                                <CenteredFlexBox sx={{ justifyContent: 'flex-start' }}>
                                    <ProjectVersion
                                        isProjectVersionSha={isSha(module.version)}
                                        version={module.version}
                                        homepage={module.homepage}
                                    />
                                </CenteredFlexBox>
                            </TableCell>
                            {[primaryProject, secondaryProject].map((selected, index) => (
                                <TableCell key={`${selected}-${index}`}>
                                    <ProjectVersionWithStatus project={module.projects[index]} />
                                </TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TactileTableBody>
            </Table>
        </TableContainer>
    ) : (
        <NoResultsFound text='No changes found' sx={{ width: '100%' }} />
    );
};
