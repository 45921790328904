import React, { ReactElement, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { Box, AppBar, Tabs, Tab, Typography, useTheme } from '@mui/material';
import { BuildDetailsSingleArtifactRegExp } from '../../utils';
import { EBuildStatus } from '../commons/enums';
import BuildGameModulesTable from '../gameModules/buildDetails/BuildGameModulesTable';
import { GameModuleCategory } from '../gameModules/enums';
import ArtifactsAccordion from './ArtifactsAccordion';
import {
    selectBuildDetailsArtifactGroups,
    selectBuildDetailsBuild,
    selectBuildDetailsProjectModules,
    selectBuildDetailsTab,
    setBuildResultTab,
} from './buildDetailsSlice';
import BuildResultButtonRow from './BuildResultButtonRow';
import { ITabPanel } from './interfaces';
import SingleArtifactAccordion from './SingleArtifactAccordion';
import StepsAccordion from './StepsAccordion';

export default function BuildResult(): ReactElement {
    const dispatch = useDispatch();
    const activeTab = useSelector(selectBuildDetailsTab);
    const artifactGroups = useSelector(selectBuildDetailsArtifactGroups);
    const build = useSelector(selectBuildDetailsBuild);
    const projectModules = useSelector(selectBuildDetailsProjectModules);
    const location = useLocation();
    const isSingleArtifact = BuildDetailsSingleArtifactRegExp.test(location.pathname);

    const handleTabChange = (_event: React.ChangeEvent<any>, newTab: string) => {
        dispatch(setBuildResultTab(newTab));
    };

    if (build?.status === EBuildStatus.queued) {
        return (
            <>
                <BuildResultButtonRow />
                <Typography
                    variant='body1'
                    color='textSecondary'
                    align='center'
                    gutterBottom
                    data-testid='build-details-queued'
                >
                    Build is queued
                </Typography>
            </>
        );
    }

    return (
        <Box p={1} sx={{ width: '100%' }}>
            {isSingleArtifact ? (
                <SingleArtifactAccordion />
            ) : (
                <>
                    <BuildResultButtonRow />
                    <Box
                        sx={{
                            flexGrow: 1,
                            width: '100%',
                        }}
                        data-testid='build-details-result'
                    >
                        <AppBar position='static' color='transparent' sx={{ boxShadow: 'none' }}>
                            <Tabs
                                value={activeTab}
                                onChange={handleTabChange}
                                indicatorColor='primary'
                                variant='scrollable'
                                scrollButtons='auto'
                            >
                                <Tab label='Steps' value='steps' data-testid='build-details-result-tab-steps' />
                                {projectModules && (
                                    <Tab
                                        label='Modules and Packages'
                                        value='game-modules'
                                        data-testid='build-details-game-modules'
                                    />
                                )}
                                {artifactGroups &&
                                    Object.keys(artifactGroups).map((group, index) => (
                                        <Tab
                                            key={index}
                                            label={group}
                                            value={group}
                                            sx={{ textTransform: 'capitalize' }}
                                            data-testid='build-details-result-tab-artifact'
                                        />
                                    ))}
                            </Tabs>
                        </AppBar>
                        <StepsPanelAccordion />
                        <GameModulesPanelTable />
                        <ArtifactGroupsPanelAccordion />
                    </Box>
                </>
            )}
        </Box>
    );
}

function TabPanel({ activeTab, group, children }: ITabPanel): ReactElement {
    const theme = useTheme();

    return (
        <div role='tabpanel' hidden={activeTab !== group}>
            {activeTab === group && (
                <Box
                    sx={{
                        [theme.breakpoints.down('sm')]: {
                            padding: 0,
                        },
                        my: 2,
                        borderRadius: 0,
                    }}
                >
                    {children}
                </Box>
            )}
        </div>
    );
}

function StepsPanelAccordion(): ReactElement {
    const activeTab = useSelector(selectBuildDetailsTab);

    return (
        <TabPanel group='steps' activeTab={activeTab}>
            <StepsAccordion />
        </TabPanel>
    );
}

function GameModulesPanelTable(): ReactElement {
    const gameModules = useSelector(selectBuildDetailsProjectModules);
    const activeTab = useSelector(selectBuildDetailsTab);
    const [category, setCategory] = useState<GameModuleCategory>(GameModuleCategory.MODULE);

    const projectModules = useMemo(() => {
        return [...(gameModules?.[category] || [])].sort((a, b) => a.moduleName.localeCompare(b.moduleName));
    }, [category, gameModules]);

    return (
        <TabPanel group='game-modules' activeTab={activeTab}>
            <BuildGameModulesTable projectModules={projectModules} category={category} onCategoryChange={setCategory} />
        </TabPanel>
    );
}

function ArtifactGroupsPanelAccordion(): ReactElement | null {
    const activeTab = useSelector(selectBuildDetailsTab);
    const artifactGroups = useSelector(selectBuildDetailsArtifactGroups);

    if (!artifactGroups) {
        return null;
    }

    return (
        <>
            {Object.keys(artifactGroups).map((group) => {
                return (
                    <TabPanel key={group} group={group} activeTab={activeTab}>
                        <ArtifactsAccordion artifacts={artifactGroups[group]} />
                    </TabPanel>
                );
            })}
        </>
    );
}
