import { ReactElement } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { Box, SxProps } from '@mui/material';
import { Typography } from '@tactileentertainment/core-designsystem';

interface NoResultsFoundProps {
    sx?: SxProps;
    text?: string;
    icon?: React.ReactNode;
}

export default function NoResultsFound({ sx = {}, text, icon }: NoResultsFoundProps): ReactElement {
    const NoResultsIcon = icon ? icon : <SearchIcon />;

    return (
        <Box
            data-testid='no-results-found'
            sx={{
                height: '100%',
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                my: 'auto',
                backgroundColor: (theme) => theme.palette.common.white,
                ...sx,
            }}
        >
            <>
                <Box sx={{ display: 'flex', color: 'text.secondary', mb: 2 }}>{NoResultsIcon}</Box>
                <Typography variant='body-2'>{text ? text : 'Nothing found'}</Typography>
            </>
        </Box>
    );
}
