import { ReactElement, SyntheticEvent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
    Alert,
    Autocomplete,
    createFilterOptions,
    FilterOptionsState,
    SxProps,
    TextField,
    Typography,
} from '@mui/material';
import { selectNewBuildProject } from '../newBuild/newBuildSlice';
import { ISlackRecipient } from '../subscriptions/interfaces';
import {
    useGetDefaultNotificationChanelQuery,
    useGetSlackRecipientsQuery,
    useLazyGetIsSlackBotInChannelQuery,
} from '../subscriptions/subscriptionsApi';

interface SlackRecipientsProps {
    selectedSubscribers: string[];
    setSelectedSubscribers: (value: string[]) => void;
    sx?: SxProps;
}

export function SlackRecipients({
    sx,
    selectedSubscribers,
    setSelectedSubscribers,
}: SlackRecipientsProps): ReactElement {
    const { selected: projectName } = useSelector(selectNewBuildProject);
    const { data: slackRecipientsData, isLoading: recipientsLoading, isError } = useGetSlackRecipientsQuery();
    const { data: defaultChannel, isLoading: defaultChanelLoading } = useGetDefaultNotificationChanelQuery(
        { projectName },
        { skip: !projectName },
    );
    const [getIsSlackBotInChannels] = useLazyGetIsSlackBotInChannelQuery();

    const [recipients, setRecipients] = useState<string[]>([]);
    const [slackBotMissingFromChannels, setSlackBotMissingFromChannels] = useState<string[]>([]);

    useEffect(() => {
        if (slackRecipientsData && recipients.length < slackRecipientsData.length) {
            const slackRecipients = slackRecipientsData.map((item) => item.email || item.name);
            setRecipients(slackRecipients);
        }
    }, [slackRecipientsData, recipients.length]);

    useEffect(() => {
        if (defaultChannel) {
            setSelectedSubscribers([defaultChannel, ...selectedSubscribers]);
            setRecipients([defaultChannel]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultChannel]);

    const handleChange = async (event: SyntheticEvent, updatedSubscribers: string[]) => {
        const selectedSlackRecipients = slackRecipientsData?.filter((recipient) =>
            updatedSubscribers.includes(recipient.email || recipient.name),
        );
        const selectedChannels = selectedSlackRecipients?.filter((subscriber) => isRecipientAChannel(subscriber));

        if (!selectedChannels || selectedChannels.length === 0) {
            setSelectedSubscribers(updatedSubscribers);
            setSlackBotMissingFromChannels([]);
            return;
        }

        if (selectedChannels.length > 10) {
            return;
        }

        setSelectedSubscribers(updatedSubscribers);

        const selectedChannelNames = selectedChannels.map((channel) => channel.name);
        const channelsHasSlackBot = await getIsSlackBotInChannels(selectedChannelNames);

        setSlackBotMissingFromChannels(
            selectedChannelNames.filter((channel) => !channelsHasSlackBot.data?.channels[channel]),
        );
    };

    return (
        <>
            {isError && (
                <Alert sx={{ my: 1 }} severity='error'>
                    Unable to get recipients. Please try again later.
                </Alert>
            )}
            <Typography sx={{ mt: 1, mb: 2 }}>
                Add any other email, user name or up to 10 channel names to get notified on Slack
            </Typography>
            <Autocomplete
                sx={{ ...sx }}
                options={recipients}
                loading={recipientsLoading || defaultChanelLoading}
                autoHighlight
                clearOnBlur
                multiple={true}
                value={selectedSubscribers}
                onChange={handleChange}
                filterOptions={filterOptions}
                renderInput={(params) => <TextField {...params} label='Emails or channels' variant='outlined' />}
            />
            {slackBotMissingFromChannels.length > 0 && (
                <Alert sx={{ my: 1 }} severity='error'>
                    TactileBot has to be added to the following channels to be notified:{' '}
                    {slackBotMissingFromChannels.join(', ')}
                </Alert>
            )}
        </>
    );
}

const isRecipientAChannel = (recipient: ISlackRecipient) =>
    recipient.id.startsWith('C') || recipient.id.startsWith('G');

const muiFilter = createFilterOptions<string>();

const filterOptions = (options: string[], state: FilterOptionsState<string>) => {
    const inputValue = state.inputValue?.toLowerCase();
    const filtered = muiFilter(options, state).sort(
        (a, b) => a.toLowerCase().indexOf(inputValue) - b.toLowerCase().indexOf(inputValue),
    );

    return filtered;
};
