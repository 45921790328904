import { createApi } from '@reduxjs/toolkit/query/react';
import { apiBaseQuery } from '../../api';
import * as queryString from '../commons/queryString';
import { ISlackRecipient, ISubscription, ISubscriptionEvent, ISubscriptionPayload } from './interfaces';

export const subscriptionsApi = createApi({
    reducerPath: 'subscriptionsApi',
    baseQuery: apiBaseQuery(),
    refetchOnMountOrArgChange: true,
    endpoints: (builder) => ({
        subscribe: builder.mutation<void, ISubscriptionPayload>({
            query: (payload: ISubscriptionPayload) => ({
                method: 'POST',
                url: 'subscriptions',
                body: payload,
            }),
        }),
        getSubscriptions: builder.query<ISubscription[], { type: string; event: ISubscriptionEvent }>({
            query: (filter) => ({
                method: 'GET',
                url: `subscriptions?${queryString.stringify({ type: filter.type, event: JSON.stringify(filter.event) })}`,
            }),
            transformResponse: (response: { subscriptions: ISubscription[] }): ISubscription[] =>
                response.subscriptions,
        }),
        removeSubscriptions: builder.mutation<void, string[]>({
            query: (ids: string[]) => ({
                method: 'DELETE',
                url: 'subscriptions',
                body: { ids },
            }),
        }),
        getSlackRecipients: builder.query<ISlackRecipient[], void>({
            query: () => ({
                method: 'GET',
                url: 'slack/recipients',
            }),
            transformResponse: (response: { recipients: ISlackRecipient[] }): ISlackRecipient[] => response.recipients,
        }),
        getIsSlackBotInChannel: builder.query<{ channels: { [channelName: string]: boolean } }, string[]>({
            query: (channelNames: string[]) => ({
                method: 'GET',
                url: `slack/can-recieve-notifications?${queryString.stringify({ channelNames })}`,
            }),
        }),
        getDefaultNotificationChanel: builder.query<string | undefined, { projectName: string }>({
            query: (filter) => ({
                method: 'GET',
                url: `projects/${filter.projectName}/default-notification-chanel`,
            }),
            transformResponse: (response: { chanel: string | undefined }) => response.chanel,
        }),
    }),
});

export const {
    useSubscribeMutation,
    useGetSubscriptionsQuery,
    useRemoveSubscriptionsMutation,
    useGetSlackRecipientsQuery,
    useGetIsSlackBotInChannelQuery,
    useLazyGetIsSlackBotInChannelQuery,
    useGetDefaultNotificationChanelQuery,
} = subscriptionsApi;
