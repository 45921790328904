import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SearchField } from '@tactileentertainment/core-designsystem';
import { AppThunkDispatch } from '../../store';
import { getBuildActions, getBuilds, getBuildsExtras, selectFilterSearch, setFilterSearch } from './buildsSlice';
import { IBuildsSliceState } from './interfaces';

export default function BuildsSearch() {
    const [searchText, setSearchText] = useState(useSelector(selectFilterSearch).selected || '');

    const dispatch = useDispatch<AppThunkDispatch<IBuildsSliceState>>();
    const handleSearchChange = (text: string | undefined) => {
        setSearchText(text || '');
        dispatch(setFilterSearch(text || ''));
        dispatch(getBuilds()).then(() => {
            dispatch(getBuildsExtras());
            dispatch(getBuildActions());
        });
    };

    return (
        <SearchField
            size='small'
            placeholder='Search build warnings'
            value={searchText}
            timeout={1000}
            sx={{
                width: { xs: '100%', md: '400px' },
                fieldset: { borderRadius: '6px' },
                mr: '24px',
                mb: { xs: '20px', md: '0' },
                mt: { xs: '20px', md: '0' },
            }}
            onSearch={handleSearchChange}
        />
    );
}
