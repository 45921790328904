import { IBuildResult } from '../../buildDetails/interfaces';
import { IConfigurationAction } from '../../newBuild/interfaces';
import { EBuildStatus } from '../enums';
import { IBuild } from '../interfaces';

export function matchAction(
    build: IBuild,
    action: IConfigurationAction,
    buildResult: IBuildResult | undefined = undefined,
): boolean {
    const availableStates = action.available_states || [EBuildStatus.finished];
    if (!availableStates.includes(build.status)) {
        return false;
    }
    return matchPrerequisites(build, buildResult, action);
}

function matchPrerequisites(
    build: IBuild,
    buildResult: IBuildResult | undefined,
    action: IConfigurationAction,
): boolean {
    if (!action.prerequisites) {
        return true;
    }
    for (const [key, value] of Object.entries(action.prerequisites)) {
        if (build[key as keyof IBuild] !== value && buildResult?.[key as keyof IBuildResult] !== value) {
            return false;
        }
    }
    return true;
}
