import { ReactElement } from 'react';
import { Box, useMediaQuery } from '@mui/material';
import { Typography } from '@tactileentertainment/core-designsystem';
import { DebounceTextField } from '../../commons/DebounceTextField';
import { useGetIsSlackBotInChannelQuery } from '../../subscriptions/subscriptionsApi';

interface ChannelInputProps {
    channel?: string;
    onChange: (channel?: string) => void;
}

export function DefaultNotificationChannel({ channel, onChange }: ChannelInputProps): ReactElement {
    const isMobile = useMediaQuery('(max-width: 760px)');
    const { data, isLoading } = useGetIsSlackBotInChannelQuery([channel] as string[], { skip: !channel });
    const shouldShowMissingMessage = !isLoading && channel && data && !data.channels[channel];

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', position: 'relative' }}>
            <DebounceTextField
                id='channel-input'
                required
                label='Notification Channel'
                variant='outlined'
                value={channel}
                onChange={(e) => onChange(e.target.value || '')}
                sx={{ minWidth: isMobile ? '0px' : '350px' }}
            />
            {shouldShowMissingMessage && (
                <Typography variant='caption' color='error' sx={{ position: 'absolute', top: '52px', left: '4px' }}>
                    TactileBot is not in the channel, add it to receive notifications
                </Typography>
            )}
        </Box>
    );
}
