import { createApi } from '@reduxjs/toolkit/query/react';
import { apiBaseQuery } from '../../api';
import { ISvnCommit } from './interfaces/ISvnCommit';

export const changeLogApi = createApi({
    reducerPath: 'changeLogApi',
    baseQuery: apiBaseQuery(),
    refetchOnMountOrArgChange: true,
    endpoints: (builder) => ({
        getLogs: builder.query<ISvnCommit[], { projectName: string; branch: string; lastRevision: string }>({
            query: ({ projectName, branch, lastRevision }) => ({
                url: `/version-control-access/logs?projectName=${projectName}&branch=${branch}&lastRevision=${lastRevision}`,
            }),
            transformResponse: (response: { logs: ISvnCommit[] }) => {
                return response.logs;
            },
        }),
    }),
});

export const { useGetLogsQuery } = changeLogApi;
