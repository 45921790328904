import { ReactElement } from 'react';
import { Table, TableCell, TableHead } from '@mui/material';
import TableBody from '../../commons/TableBody';
import TableContainer from '../../commons/TableContainer';
import TableHeaderRow from '../../commons/TableHeaderRow';
import { IModule } from '../interfaces';
import GameModuleProjectRow from './GameModuleProjectRow';
import GameModuleProjectsLoadingRows from './GameModuleProjectsLoadingRows';

interface GameModuleProjectsTableProps {
    module?: IModule;
    isLoading: boolean;
}

export default function GameModuleProjectsTable({ module, isLoading }: GameModuleProjectsTableProps): ReactElement {
    if (!module) {
        return <></>;
    }

    const projects =
        module?.projects?.slice().sort((a, b) => (a?.projectName ?? '').localeCompare(b?.projectName ?? '')) || [];

    return (
        <TableContainer>
            <Table
                stickyHeader
                aria-label='game modules table'
                sx={{ width: '100%', minWidth: 1000, tableLayout: 'fixed' }}
            >
                <TableHead data-testid='game-module-projects-table-head'>
                    <TableHeaderRow>
                        <TableCell key='games' sx={{ width: 200 }}>
                            Games
                        </TableCell>
                        <TableCell key='branch' sx={{ width: 100 }}>
                            Branch
                        </TableCell>
                        <TableCell key='latest-version' sx={{ width: 100 }}>
                            Latest Version
                        </TableCell>
                        <TableCell key='current-version' sx={{ width: 100 }}>
                            Current Version
                        </TableCell>
                    </TableHeaderRow>
                </TableHead>
                <TableBody data-testid='game-module-projects-table-body'>
                    {isLoading ? (
                        <GameModuleProjectsLoadingRows rows={30} />
                    ) : (
                        module &&
                        projects.map((project) => {
                            return project ? (
                                <GameModuleProjectRow
                                    key={project.projectName}
                                    currentProject={project}
                                    name={project.projectName}
                                    homepage={project.homepage}
                                />
                            ) : null;
                        })
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
