import { ReactElement } from 'react';
import Moment from 'react-moment';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Box, FormControlLabel, Link, TableCell } from '@mui/material';
import { Checkbox, Chip, Typography } from '@tactileentertainment/core-designsystem';
import { ShowIfCan } from '@tactileentertainment/core-shared.auth-react';
import ClickableTableRow from '../commons/ClickableTableRow';
import { EAuthResource } from '../commons/EAuthResource';
import { StickyTableCell } from './GameModulesTable';
import GameModuleStatus from './GameModuleStatus';
import { IModule } from './interfaces';

interface GameModuleRowProps {
    module: IModule;
    projectList: string[];
    selectedItems: Array<Partial<IModule>>;
    onSelectedChange: (item: Array<Partial<IModule>>) => void;
}

export default function GameModuleRow({
    module,
    projectList,
    selectedItems,
    onSelectedChange,
}: GameModuleRowProps): ReactElement {
    const isBuiltIn = module.version === 'builtin';

    const onMouseOverHandler = (projectName: string) => {
        document.querySelectorAll(`[id="${projectName}"]`).forEach((element) => element.classList.add('Mui-selected'));
    };

    const onMouseLeaveHandler = (projectName: string) => {
        document
            .querySelectorAll(`[id="${projectName}"]`)
            .forEach((element) => element.classList.remove('Mui-selected'));
    };

    const handleRowChecked = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            onSelectedChange([
                ...selectedItems,
                { moduleName: module.moduleName, deprecated: module.deprecated, primary: module.primary },
            ]);
        } else {
            onSelectedChange(selectedItems.filter((itm) => itm.moduleName !== module.moduleName));
        }
    };

    return (
        <ClickableTableRow
            redirectUrl={`/modules/${encodeURIComponent(module.moduleName)}`}
            sx={{
                ':last-of-type .MuiTableCell-root': {
                    borderWidth: '1px',
                },
                ':nth-of-type(even) .MuiTableCell-root': {
                    backgroundColor: (theme) => theme.palette.surface.dark,
                },
                ':nth-of-type(odd) .MuiTableCell-root': {
                    backgroundColor: (theme) => theme.palette.common.white,
                },
            }}
        >
            <ShowIfCan resource={EAuthResource.modules} operation='edit'>
                <StickyTableCell sx={{ zIndex: 9, width: '10%' }}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                onChange={handleRowChecked}
                                checked={
                                    !!selectedItems.find((mod) => mod.moduleName === module.moduleName) &&
                                    !!selectedItems.length
                                }
                            />
                        }
                        label=''
                    />
                </StickyTableCell>
            </ShowIfCan>
            <StickyTableCell title={module.moduleName} sx={{ left: 0, zIndex: 10 }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Box sx={{ display: 'inline-flex', justifyContent: 'flex-start' }}>
                        <span
                            style={{
                                display: 'block',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                            }}
                        >
                            {module.moduleName}
                        </span>
                        {module.homepage && (
                            <Link
                                target='_blank'
                                href={module.homepage}
                                onMouseUp={(event) => event.stopPropagation()}
                                sx={{ ml: 1, display: 'flex' }}
                            >
                                <OpenInNewIcon fontSize='small' />
                            </Link>
                        )}
                    </Box>
                    <StatusChip module={module} />
                </Box>
            </StickyTableCell>
            <StickyTableCell sx={{ left: 300, zIndex: 11 }}>
                {!isBuiltIn && module.versionUpdatedAt ? (
                    <Moment format='DD/MM/YYYY'>{module.versionUpdatedAt}</Moment>
                ) : (
                    <Typography color='light'>N/A</Typography>
                )}
            </StickyTableCell>
            <StickyTableCell
                title={module.version}
                sx={{
                    left: 430,
                    zIndex: 12,
                    boxShadow: '16px 0px 19px -2px rgba(16, 24, 40, 0.03)',
                    borderRight: (theme) => `1px solid ${theme.palette.tactile.blue[300]}`,
                }}
            >
                <Box
                    component='span'
                    sx={{
                        display: 'block',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        color: isBuiltIn ? (theme) => theme.palette.text.secondary : 'default',
                    }}
                >
                    {isBuiltIn ? 'Built-In' : module.version}
                </Box>
            </StickyTableCell>
            {projectList.map((projectName) => {
                const project = module.projects?.find((moduleProject) => moduleProject?.projectName === projectName);
                return (
                    <TableCell
                        onMouseOver={() => onMouseOverHandler(projectName)}
                        onMouseLeave={() => onMouseLeaveHandler(projectName)}
                        id={projectName}
                        key={projectName}
                        align='center'
                        sx={{
                            px: 0.5,
                            '&.MuiTableCell-root.Mui-selected': {
                                backgroundColor: (theme) => theme.palette.grey[200],
                            },
                        }}
                    >
                        <GameModuleStatus project={project} />
                    </TableCell>
                );
            })}
            <TableCell />
        </ClickableTableRow>
    );
}

export const StatusChip = ({ module }: { module: IModule }) => {
    return (
        <Box sx={{ display: 'inline-flex', justifyContent: 'space-between' }}>
            {module.primary && (
                <Chip
                    sx={{
                        ml: 1,
                        background: (theme) => theme.palette.tactile.blue[100],
                        color: (theme) => theme.palette.tactile.blue[700],
                    }}
                    size='small'
                    label='Primary'
                />
            )}
            {module.deprecated && (
                <Chip
                    sx={{
                        ml: 1,
                        background: (theme) => theme.palette.tactile.red[100],
                        color: (theme) => theme.palette.tactile.red[700],
                    }}
                    size='small'
                    label='Deprecated'
                />
            )}
        </Box>
    );
};
