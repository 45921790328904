import { ReactElement } from 'react';
import BuildProjectName from '../../builds/BuildProjectName';
import ClickableTableRow from '../../commons/ClickableTableRow';
import { IToggleColumn } from '../../commons/interfaces';
import TableTextCell from '../../commons/TableTextCell';
import { IProject } from '../interfaces';
import { ProjectsColumnId } from '../projectsColumns';
import ProjectAuthorsCell from './ProjectAuthorsCell';
import ProjectAvailabilityCell from './ProjectAvailabilityCell';
import ProjectStatusCell from './ProjectStatusCell';

export interface IProjectRowProps {
    project: IProject;
    columns: Array<IToggleColumn<ProjectsColumnId>>;
}

export default function ProjectRow({ project, columns }: IProjectRowProps): ReactElement {
    const redirectUrl = `/projects/${project._id}/`;

    return (
        <ClickableTableRow redirectUrl={redirectUrl} data-testid='project-row'>
            {columns.map((column) => {
                switch (column.id) {
                    case ProjectsColumnId.project:
                        return <BuildProjectName key={column.id} align={column.align} projectName={project.name} />;

                    case ProjectsColumnId.svnPath:
                        return <TableTextCell key={column.id} align={column.align} text={project.svnPath || '-'} />;

                    case ProjectsColumnId.unityVersion:
                        return (
                            <TableTextCell
                                key={column.id}
                                align={column.align}
                                text={project.latestRequirements?.unity || '-'}
                            />
                        );

                    case ProjectsColumnId.isEnabled:
                        return <ProjectStatusCell key={column.id} align={column.align} isEnabled={project.isEnabled} />;

                    case ProjectsColumnId.agents:
                        return (
                            <TableTextCell
                                key={column.id}
                                align={column.align}
                                text={project.agents.length.toString()}
                            />
                        );

                    case ProjectsColumnId.managers:
                        return <ProjectAuthorsCell key={column.id} authors={project.managers} />;

                    case ProjectsColumnId.isOrderable:
                        return (
                            <ProjectAvailabilityCell
                                key={column.id}
                                align={column.align}
                                isOrderable={project.isOrderable}
                            />
                        );

                    default:
                        return <TableTextCell key={column.id} align={column.align} text={project[column.id]} />;
                }
            })}
        </ClickableTableRow>
    );
}
