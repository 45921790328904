import { ReactElement, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Paper } from '@mui/material';
import { getUrlFilterValue } from '../commons/getUrlPrams';
import HeaderSection from '../commons/HeaderSection';
import NoResultsFound from '../commons/NoResultsFound';
import Page from '../commons/Page';
import * as queryString from '../commons/queryString';
import { modulesMenu } from '../header/menuItems';
import { CompareModulesAction } from './compareModulesAction/CompareModulesAction';
import { GameModuleCategory } from './enums';
import { gameModulesApi, useGetGameModulesQuery } from './gameModulesApi';
import GameModulesFilters from './GameModulesFilters';
import GameModulesTable from './GameModulesTable';
import { IGameModulesFilterOptions, IModule } from './interfaces';
import { filterModules, formatDate } from './utils';

export default function GameModules(): ReactElement {
    const dispatch = useDispatch();
    const { search } = useLocation();
    const [filters, setFilters] = useState<IGameModulesFilterOptions>(getFiltersFromUrl(search));
    const [selected, setSelected] = useState<Array<Partial<IModule>>>([]);
    const { data, isLoading } = useGetGameModulesQuery({ includeFields: ['projects'] });

    useEffect(() => {
        return () => {
            dispatch(gameModulesApi.util.resetApiState());
        };
    }, [dispatch]);

    const gameProjects = useMemo(() => {
        const allModules = [...(data?.modules?.module ?? []), ...(data?.modules?.package ?? [])];
        const projects = allModules.map((module) =>
            (module.projects || []).map((project) => project?.projectName ?? ''),
        );

        return [...new Set(projects.flat())].sort();
    }, [data?.modules?.module, data?.modules?.package]);

    const modules = useMemo(() => {
        return filterModules({
            modules: data?.modules,
            searchText: filters.searchText,
            category: filters.tab,
            selectedProjects: filters.selectedProjects,
            hideModules: filters.hideModules,
            branchesOnly: filters.branchesOnly,
            showOnlyNonUpToDate: !!filters.showOnlyNonUpToDate,
            showOnlyDiff: false,
            primary: filters.primary,
            deprecated: filters.deprecated,
        });
    }, [
        data?.modules,
        filters.hideModules,
        filters.searchText,
        filters.selectedProjects,
        filters.tab,
        filters.branchesOnly,
        filters.showOnlyNonUpToDate,
        filters.primary,
        filters.deprecated,
    ]);

    const projectList = filters.selectedProjects?.length ? filters.selectedProjects : gameProjects;
    const lastSynced = data?.lastUpdatedAt ? formatDate(data.lastUpdatedAt) : '…';

    useEffect(() => {
        setFiltersToUrl(filters);
    }, [filters]);

    return (
        <Page>
            <HeaderSection
                title='Game Modules'
                subTitle={lastSynced ? `Last synced ${lastSynced}` : undefined}
                helmetTitle='Game Modules'
                actionBox={<CompareModulesAction gamesList={gameProjects} />}
            >
                <GameModulesFilters
                    selectedItems={selected}
                    filters={filters}
                    onFilterChange={(newFilters) => {
                        setFilters((currentFilters) => ({ ...currentFilters, ...newFilters }));
                        if (newFilters.showLoading) {
                            setTimeout(
                                () => setFilters((currentFilters) => ({ ...currentFilters, showLoading: false })),
                                500,
                            );
                        }
                    }}
                    projectList={gameProjects}
                    onSelectedChange={setSelected}
                />
            </HeaderSection>
            <Paper data-testid='game-modules' sx={{ display: 'flex', flex: 1, overflow: 'hidden' }}>
                {(modules.length > 0 || isLoading) && (
                    <GameModulesTable
                        selectedItems={selected}
                        onSelectedChange={setSelected}
                        modules={modules}
                        projectList={filters.showLoading ? [] : projectList}
                        isLoading={isLoading || !!filters.showLoading}
                    />
                )}
                {modules.length === 0 && !isLoading && (
                    <NoResultsFound text='Nothing found, please try again.' sx={{ width: '100%' }} />
                )}
            </Paper>
        </Page>
    );
}

function getFiltersFromUrl(search: string): IGameModulesFilterOptions {
    const params = queryString.parse(search);
    return {
        searchText: (params.searchText as string) || '',
        selectedProjects: getUrlFilterValue('selectedProjects') || [],
        tab: (params.tab as GameModuleCategory) || GameModuleCategory.MODULE,
        hideModules: params.hideModules ? (params.hideModules as boolean) : true,
        branchesOnly: params.branchesOnly ? (params.branchesOnly as boolean) : false,
        showOnlyNonUpToDate: params.nonUpToDate ? (params.nonUpToDate as boolean) : false,
        primary: params.primary ? (params.primary as boolean) : false,
        deprecated: params.deprecated ? (params.deprecated as boolean) : false,
    };
}

function setFiltersToUrl(filters: IGameModulesFilterOptions): void {
    const newParams = queryString.stringify({
        searchText: filters.searchText,
        selectedProjects: filters.selectedProjects,
        tab: filters.tab,
        hideModules: filters.hideModules,
        branchesOnly: filters.branchesOnly,
        nonUpToDate: filters.showOnlyNonUpToDate,
        primary: filters.primary,
        deprecated: filters.deprecated,
    });

    window.history.replaceState(null, 'update search query', newParams ? `?${newParams}` : modulesMenu.to);
}
