import { ReactElement } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CancelIcon from '@mui/icons-material/Cancel';
import QueueIcon from '@mui/icons-material/Queue';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Box, ButtonProps, CircularProgress, TableCell } from '@mui/material';
import { SquareButton } from '@tactileentertainment/core-designsystem';
import { useAuthUser } from '@tactileentertainment/core-shared.auth-react';
import { MuiAlignSetting } from '../../typings/material-ui';
import { toggleShowActionConfirmationDialog } from '../commons/commonsSlice';
import DownloadBuildButton from '../commons/DownloadBuildButton';
import { EBuildStatus, BuildDialogActionType } from '../commons/enums';
import BuildActionsButton from './BuildActionsButton';
import { selectBuildsActionsLoading } from './buildsSlice';
import { IBuildRow } from './interfaces';

interface BuildActionsProps {
    align?: MuiAlignSetting;
    build: IBuildRow;
}

export default function BuildActions({ align = 'right', build }: BuildActionsProps): ReactElement {
    return (
        <TableCell align={align} sx={{ padding: 1 }} data-testid='builds-table-build-actions'>
            <GetActionIcons build={build} />
        </TableCell>
    );
}

function GetActionIcons({ build }: BuildActionsProps): ReactElement | null {
    const isActionLoading = useSelector(selectBuildsActionsLoading);

    return (
        <Box display='flex'>
            {isActionLoading ? (
                <Box sx={{ p: 1.25 }}>
                    <CircularProgress size={14} />
                </Box>
            ) : (
                build.actions && build.actions.length > 0 && <BuildActionsButton build={build} />
            )}
            {build.status === EBuildStatus.running && <CancelBuildButton build={build} />}
            {build.status === EBuildStatus.finished && (
                <DownloadBuildButton
                    buildNumber={build.buildNumber}
                    buildStatus={build.status}
                    projectName={build.projectName}
                    artifact={build.mainArtifact}
                />
            )}
        </Box>
    );
}

interface BuildActionButtonProps {
    build: IBuildRow;
    variant?: ButtonProps['variant'];
}

export function ReorderBuildButton({ build }: BuildActionButtonProps): ReactElement {
    const dispatch = useDispatch();
    const { isLoggedIn } = useAuthUser();

    const handleClick = async (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        event.stopPropagation();

        dispatch(toggleShowActionConfirmationDialog({ action: BuildDialogActionType.reorder, build }));
    };

    return (
        <SquareButton
            disabled={!isLoggedIn}
            title='Reorder'
            aria-label='reorder'
            onClick={handleClick}
            color='secondary'
            variant='outlined'
            sx={{ mr: 1, my: { xs: 2, sm: 0 } }}
        >
            <RefreshIcon color='secondary' />
        </SquareButton>
    );
}

export function CancelBuildButton({ build, variant }: BuildActionButtonProps): ReactElement {
    const dispatch = useDispatch();
    const { isLoggedIn } = useAuthUser();

    const handleClick = async (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        event.stopPropagation();

        dispatch(toggleShowActionConfirmationDialog({ action: BuildDialogActionType.cancel, build }));
    };

    return (
        <SquareButton
            variant={variant}
            disabled={!isLoggedIn}
            title='Cancel'
            aria-label='cancel'
            onMouseUp={handleClick}
            color='secondary'
            sx={{ p: 1.25 }}
        >
            <CancelIcon />
        </SquareButton>
    );
}

export function OrderTestButton({ build, variant }: BuildActionButtonProps): ReactElement {
    const dispatch = useDispatch();
    const { isLoggedIn } = useAuthUser();

    const handleClick = async (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        event.stopPropagation();

        dispatch(toggleShowActionConfirmationDialog({ action: BuildDialogActionType.orderTest, build }));
    };

    return (
        <SquareButton
            disabled={!isLoggedIn}
            title='Order Test'
            aria-label='orderTest'
            onClick={handleClick}
            color='secondary'
            variant={variant}
        >
            <QueueIcon />
        </SquareButton>
    );
}
