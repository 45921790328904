import { Tab, Tabs } from '@tactileentertainment/core-designsystem';
import { GameModuleCategory } from '../enums';

interface BuildGameModulesTableTabsProps {
    category: GameModuleCategory;
    onCategoryChange: (category: GameModuleCategory) => void;
}

export default function BuildGameModulesTableTabs({ category, onCategoryChange }: BuildGameModulesTableTabsProps) {
    return (
        <Tabs
            noBorder
            value={category}
            sx={{
                display: 'flex',
                alignItems: 'center',
                '.MuiTab-root': {
                    px: 2,
                    borderRadius: '6px',
                    padding: '8px 12px',
                    fontWeight: 600,
                    m: 0,
                    mr: 1,
                    minHeight: 36,
                    '&.Mui-selected': {
                        background: (theme) => theme.palette.tactile.neutral[400],
                        color: (theme) => theme.palette.tactile.neutral[800],
                    },
                },
                '& .MuiTabs-indicator': {
                    display: 'none',
                },
            }}
            onChange={onCategoryChange}
        >
            <Tab key={GameModuleCategory.MODULE} label='Modules' value={GameModuleCategory.MODULE} />
            <Tab key={GameModuleCategory.PACKAGE} label='Packages' value={GameModuleCategory.PACKAGE} />
            <Tab key={GameModuleCategory.UNITY_PACKAGE} label='Unity' value={GameModuleCategory.UNITY_PACKAGE} />
        </Tabs>
    );
}
