import { ReactElement, useMemo, useState } from 'react';
import { MoveUp as ActionsIcon } from '@mui/icons-material';
import { Box } from '@mui/material';
import { SquareButton } from '@tactileentertainment/core-designsystem';
import BuildActionsMenu from '../commons/buildAction/BuildActionsMenu';
import { matchAction } from '../commons/utils/matchAction';
import { IConfigurationAction } from '../newBuild/interfaces';
import { IBuildRow } from './interfaces';

interface IBuildActionsButtonProps {
    build: IBuildRow;
}

export default function BuildActionsButton({ build }: IBuildActionsButtonProps): ReactElement {
    const [anchorEl, setAnchorEl] = useState<Element | null>(null);

    const openMenu = (event: React.MouseEvent) => {
        setAnchorEl(event.currentTarget);
    };

    const closeMenu = () => {
        setAnchorEl(null);
    };

    const availableActions: IConfigurationAction[] | undefined = useMemo(() => {
        return build.actions?.filter((action) => matchAction(build, action));
    }, [build]);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        event.stopPropagation();

        openMenu(event);
    };

    return (
        <>
            {!!availableActions?.length && (
                <Box onMouseUp={(event) => event.stopPropagation()}>
                    <SquareButton
                        title='Actions'
                        aria-label='actions'
                        color='secondary'
                        sx={{ p: 1.25, pr: 1 }}
                        onMouseUp={handleClick}
                    >
                        <ActionsIcon color='secondary' />
                    </SquareButton>
                    <BuildActionsMenu
                        anchorEl={anchorEl}
                        closeMenu={closeMenu}
                        availableActions={availableActions}
                        build={build}
                    />
                </Box>
            )}
        </>
    );
}
