import { useMemo, useState } from 'react';
import SwapHorizontalCircleIcon from '@mui/icons-material/SwapHorizontalCircle';
import { Box } from '@mui/material';
import {
    Alert,
    Button,
    Dialog,
    LoadingScreen,
    SearchField,
    SoloButtonGroup,
} from '@tactileentertainment/core-designsystem';
import { useGetProjectBranchesQuery } from '../../buildAgents/buildAgentsApi';
import { GameAndBranchSelector } from '../../changeLog/GameAndBranchSelector';
import { GameModuleCategory } from '../enums';
import { useCompareGameModulesQuery } from '../gameModulesApi';
import { IGameModulesFilterOptions } from '../interfaces';
import { filterModules } from '../utils';
import { CompareModulesTable } from './CompareModulesTable';

export interface ICompareModulesActionProps {
    gamesList: string[];
}

export const CompareModulesAction = ({ gamesList }: ICompareModulesActionProps) => {
    const [openCompareDialog, setOpenCompareDialog] = useState(false);

    const handleCloseDialog = () => {
        setOpenCompareDialog(false);
    };

    return (
        <>
            <Button color='secondary' variant='outlined' onClick={() => setOpenCompareDialog(true)}>
                Compare
            </Button>
            <Dialog
                variant='custom'
                open={openCompareDialog}
                onClose={handleCloseDialog}
                title='Compare Modules'
                subtitle='Select modules, packages and compare the versions'
                content={<CompareModulesDialogContent gamesList={gamesList} />}
                primaryButton={{
                    text: 'Done',
                    onClick: handleCloseDialog,
                }}
                maxWidth='lg'
            />
        </>
    );
};

interface ICompareModulesDialogContentProps {
    gamesList: string[];
}

const TRUNK_BRANCH = 'trunk';

export const CompareModulesDialogContent = ({ gamesList }: ICompareModulesDialogContentProps) => {
    const [primaryProject, setPrimaryProject] = useState<string | undefined>();
    const [secondaryProject, setSecondaryProject] = useState<string | undefined>();

    const [primaryBranch, setPrimaryBranch] = useState<string | undefined>();
    const [secondaryBranch, setSecondaryBranch] = useState<string | undefined>();

    const [filters, setFilters] = useState<Partial<IGameModulesFilterOptions>>({
        tab: GameModuleCategory.MODULE,
        hideModules: true,
    });

    const { data: primaryBranches, isLoading: primaryBranchesLoading } = useGetProjectBranchesQuery(
        { projectName: primaryProject as string },
        {
            skip: !primaryProject,
        },
    );

    const { data: secondaryBranches, isLoading: secondaryBranchesLoading } = useGetProjectBranchesQuery(
        { projectName: secondaryProject as string },
        {
            skip: !secondaryProject,
        },
    );

    const {
        data: modules,
        isLoading,
        isFetching,
        isError,
    } = useCompareGameModulesQuery(
        {
            projectA: primaryProject as string,
            projectB: secondaryProject as string,
            branchA: primaryBranch,
            branchB: secondaryBranch,
        },
        { skip: !primaryProject || !secondaryProject },
    );

    const handleFilterChange = (newFilters: Partial<IGameModulesFilterOptions>) => {
        setFilters({ ...filters, ...newFilters });
    };

    const filteredModules = useMemo(() => {
        if (modules) {
            const filteredTab = filterModules({
                modules: modules?.modules,
                searchText: filters.searchText,
                category: filters.tab,
                selectedProjects: filters.selectedProjects,
                hideModules: true,
                showOnlyDiff: true,
            } as any);

            const copy = structuredClone(modules);
            copy.modules[filters.tab as GameModuleCategory] = filteredTab;
            return copy;
        }
    }, [modules, filters.searchText, filters.selectedProjects, filters.tab]);

    const showTableAndFilters = primaryProject && secondaryProject;
    return (
        <Box display='flex' flexDirection='column' gap={3}>
            <Box display='flex' flexDirection={{ xs: 'column', md: 'row' }} alignItems='center' gap={1}>
                <GameAndBranchSelector
                    projects={gamesList}
                    onChangeProject={(project) => {
                        setPrimaryProject(project);
                        setPrimaryBranch(TRUNK_BRANCH);
                    }}
                    selectedProject={primaryProject}
                    branches={primaryBranches ? [...primaryBranches, TRUNK_BRANCH] : []}
                    onChangeBranch={setPrimaryBranch}
                    selectedBranch={primaryBranch}
                    branchesLoading={primaryBranchesLoading}
                />
                <SwapHorizontalCircleIcon sx={{ color: (theme) => theme.palette.tactile.gray[500] }} />
                <GameAndBranchSelector
                    projects={gamesList}
                    onChangeProject={(project) => {
                        setSecondaryProject(project);
                        setSecondaryBranch(TRUNK_BRANCH);
                    }}
                    selectedProject={secondaryProject}
                    branches={secondaryBranches ? [...secondaryBranches, TRUNK_BRANCH] : []}
                    onChangeBranch={setSecondaryBranch}
                    selectedBranch={secondaryBranch}
                    branchesLoading={secondaryBranchesLoading}
                />
            </Box>
            {showTableAndFilters && (
                <>
                    <SoloButtonGroup
                        value={filters.tab}
                        options={[
                            { label: 'Modules', id: GameModuleCategory.MODULE },
                            { label: 'Packages', id: GameModuleCategory.PACKAGE },
                            { label: 'Unity', id: GameModuleCategory.UNITY_PACKAGE },
                        ]}
                        onChange={(value) => handleFilterChange({ tab: value as GameModuleCategory })}
                    />
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: { xs: 'flex-start', md: 'center' },
                            flexDirection: { xs: 'column', md: 'row' },
                        }}
                    >
                        <SearchField
                            size='small'
                            placeholder='Search'
                            value={filters.searchText || ''}
                            timeout={200}
                            onSearch={(text) => handleFilterChange({ searchText: text as string, showLoading: true })}
                            sx={{
                                width: { xs: '100%', md: 320 },
                                mr: { xs: 0, md: 2 },
                            }}
                        />
                    </Box>
                    {(isLoading || isFetching) && <LoadingScreen />}
                    {isError && <Alert severity='error'>Something went wrong.</Alert>}
                    {filteredModules !== undefined && (
                        <CompareModulesTable
                            primaryProject={primaryProject.toString()}
                            secondaryProject={secondaryProject.toString()}
                            primaryBranch={primaryBranch?.toString() || ''}
                            secondaryBranch={secondaryBranch?.toString() || ''}
                            modulesDiffData={filteredModules.modules}
                            tab={filters.tab as GameModuleCategory}
                        />
                    )}
                </>
            )}
        </Box>
    );
};
