import { ReactElement } from 'react';
import { Menu } from '@mui/material';
import { IBuildRow } from '../../builds/interfaces';
import { IConfigurationAction } from '../../newBuild/interfaces';
import { BuildAction } from './BuildAction';

interface IBuildActionsMenuProps {
    anchorEl: Element | null;
    closeMenu: () => void;
    availableActions: IConfigurationAction[] | undefined;
    build: IBuildRow;
}

export default function BuildActionsMenu({
    anchorEl,
    closeMenu,
    availableActions,
    build,
}: IBuildActionsMenuProps): ReactElement {
    return (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={closeMenu}
            disableScrollLock
        >
            {availableActions?.map((action) => (
                <BuildAction key={action.name} action={action} build={build} onClick={closeMenu} />
            ))}
        </Menu>
    );
}
